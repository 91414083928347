import React from "react";

const BuySectionImage = ({ src }) => {
    const style = {
        backgroundImage: `url(${src})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "100%",
        borderRadius: "100rem",
    };

    return <div style={style} />;
};

export default BuySectionImage;
