// connectors.ts
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

const network = process.env.REACT_APP_NETWORK;
const isTestnet = network === "testnet";

export const injectedConnector = new InjectedConnector({
    supportedChainIds: isTestnet ? [80001] : [137],
});

export const walletConnectConnector = new WalletConnectConnector({
    rpc: {
        1: isTestnet
            ? process.env.REACT_APP_ALCHEMY_MUMBAI_RPC_URL
            : process.env.REACT_APP_CONTRACT_POLYGON,
    },
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
});
