import React from "react";

import image2 from "../../../assets/images/IMG_3099-sharpen-motion.webp";
import image3 from "../../../assets/images/IMG_3183-sharpen-focus.webp";
import image4 from "../../../assets/images/IMG_1903.webp";
import image5 from "../../../assets/images/IMG_3585-sharpen-focus.webp";
import image6 from "../../../assets/images/IMG_4615-sharpen-focus.webp";
import image7 from "../../../assets/images/IMG_4978-sharpen-focus.webp";
import image8 from "../../../assets/images/IMG_5569.JPG";
import image9 from "../../../assets/images/IMG_6299.webp";

const CollectionBirdGrid = () => {
    return (
        <div className="w-full md:w-1/2 grid grid-cols-12 grid-rows-3 gap-4 p-4">
            <div
                className="col-span-3 row-span-1 h-40 bg-cover bg-center shadow-xl rounded-3xl"
                style={{ backgroundImage: `url(${image2})` }}
            ></div>
            <div
                className="col-span-6 row-span-1 h-40 bg-cover bg-center shadow-xl rounded-3xl"
                style={{ backgroundImage: `url(${image3})` }}
            ></div>
            <div
                className="col-span-3 row-span-1 h-40 bg-cover bg-center shadow-xl rounded-3xl"
                style={{ backgroundImage: `url(${image4})` }}
            ></div>
            <div
                className="col-span-6 row-span-1 h-40 bg-cover bg-center shadow-xl rounded-3xl"
                style={{ backgroundImage: `url(${image5})` }}
            ></div>
            <div
                className="col-span-6 row-span-1 h-40 bg-cover bg-center shadow-xl rounded-3xl"
                style={{ backgroundImage: `url(${image6})` }}
            ></div>
            <div
                className="col-span-4 row-span-1 h-40 bg-cover bg-center shadow-xl rounded-3xl"
                style={{ backgroundImage: `url(${image7})` }}
            ></div>
            <div
                className="col-span-4 row-span-1 h-40 bg-cover bg-center shadow-xl rounded-3xl"
                style={{ backgroundImage: `url(${image8})` }}
            ></div>
            <div
                className="col-span-4 row-span-1 h-40 bg-cover bg-center shadow-xl rounded-3xl"
                style={{ backgroundImage: `url(${image9})` }}
            ></div>
        </div>
    );
};

export default CollectionBirdGrid;
