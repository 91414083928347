import React from "react";
import Header from "./components/Header";
import Button from "components/Button";
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="min-h-screen flex flex-col">
            <Header />
            <div className="flex-grow flex items-center justify-center p-6 sm:p-10 text-center">
                <div className="w-full max-w-md">
                    <h1 className="text-6xl font-heading font-bold mb-4 text-shadow">
                        404: Page Not Found
                    </h1>
                    <p className="mb-8 text-lg text-shadow">
                        Oops! The page you&apos;re looking for doesn&apos;t
                        exist.
                    </p>
                    <Link to="/">
                        <Button>Return to home</Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
