import React from "react";
import { FaTwitter, FaDiscord, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="text-white py-6">
            <div className="container sm:w-4/5 mx-auto px-4 flex flex-col sm:flex-row justify-between items-center">
                <div className="order-3 sm:order-1 text-sm mt-4 sm:mt-0 ">
                    <p className="mb-2">info@phauna.earth</p>
                    <p>
                        &copy; {new Date().getFullYear()} Phauna B.V. All rights
                        reserved.
                    </p>
                </div>

                <div className="order-1 sm:order-3 flex space-x-6 mb-4 sm:mb-0 sm:mt-6">
                    <a
                        href="https://twitter.com/Phauna_earth"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-4xl sm:text-2xl hover:text-tertiary transition-all duration-200 text-shadow"
                    >
                        <FaTwitter />
                    </a>
                    <a
                        href={process.env.REACT_APP_DISCORD_INVITE}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-4xl sm:text-2xl hover:text-tertiary transition-all duration-200 text-shadow"
                    >
                        <FaDiscord />
                    </a>

                    <a
                        href="https://instagram.com/sophron.earth"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-4xl sm:text-2xl  hover:text-tertiary transition-all duration-200 text-shadow"
                    >
                        <FaInstagram />
                    </a>
                </div>

                <div className="order-2 sm:order-2 flex flex-col sm:flex-row space-x-0 sm:space-x-4 space-y-4 sm:space-y-0 mr-0 sm:mr-32 sm:mt-6">
                    <Link
                        to="/terms"
                        className="text-sm hover:text-tertiary transition-all duration-200 text-shadow"
                    >
                        Terms of Use
                    </Link>
                    <Link
                        to="/privacy"
                        className="text-sm hover:text-tertiary transition-all duration-200 text-shadow"
                    >
                        Privacy Policy
                    </Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
