import React from "react";
import CollectionBirdGrid from "./CollectionBirdGrid";
// import Button from "components/Button";

const CollectionBuySection = () => {
    return (
        <div className="content-container mt-48 sm:mt-20 mb-20">
            <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
                <div className="sm:pl-4 mb-8 md:mb-0">
                    <div className="flex flex-col justify-center mb-8 -mt-28">
                        <h3 className="text-3xl sm:text-4xl font-heading text-left w-full sm:w-4/5 ml-1/5 mt-0 sm:mt-20 leading-snug">
                            Our genesis collection launches on <br></br> August
                            1, 2023
                        </h3>
                        <p className="text-lg text-left w-full sm:w-4/5 ml-1/5 mt-5 text-shadow">
                            Make sure you don&apos;t miss out by subscribing to
                            our newsletter.
                        </p>
                    </div>

                    <div>
                        <iframe
                            src="https://embeds.beehiiv.com/b51d290a-9170-415e-a6d4-0e5c7c54479e?slim=true"
                            data-test-id="beehiiv-embed"
                            height="52"
                            frameBorder="0"
                            scrolling="no"
                            style={{
                                margin: 0,
                                borderRadius: 8,
                                backgroundColor: "transparent",
                                boxShadow: "0 8px 16px 0 rgba(0,0,0,0.07)",
                            }}
                        />
                    </div>
                    <div className="mt-12">
                        {/* <Button
                            href="https://discord.gg/Hz4rKhRB"
                            target="_blank"
                            rel="noreferrer"
                            className="text-xl sm:text-base md:text-lg lg:text-2xl font-heading px-4 sm:px-6 py-2 sm:py-3 transition-all duration-300"
                            data-aos="fade-up"
                            data-aos-duration="800"
                            data-aos-delay="400"
                        >
                            Go to the buy page
                        </Button> */}
                    </div>
                </div>
                <CollectionBirdGrid />
            </div>
        </div>
    );
};

export default CollectionBuySection;
