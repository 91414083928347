import React, { useRef } from "react";
import Header from "../Header";
import HeroSection from "./HeroSection";
// import RestorationProjectsSection from "./restoration_sections/RestorationProjectsSection";
import RestorationLandSection from "./restoration_sections/RestorationLandSection";
import RestorationQuoteSection from "./restoration_sections/RestorationQuoteSection";
import RestorationIUCNSection from "./restoration_sections/RestorationIUCNSection";
import RestorationCTASection from "./restoration_sections/RestorationCTASection";
import RestorationStatsSection from "./restoration_sections/RestorationStatsSection";
// import RestorationTempIntroSection from "./restoration_sections/RestorationTempIntroSection";
// import HomepageRestorationSection from "./homepage_sections/HomepageRestorationSection";
import backgroundImage from "../../assets/images/iucn_redmonkey.jpg";
// import RestorationProjectsBASection from "./restoration_sections/RestorationProjectsBASection";
import RestorationWashuSection from "./restoration_sections/RestorationWashuSection";

const RestorationPage = () => {
    const heroRef = useRef();

    return (
        <div className="gradient-bg min-h-screen">
            <Header sectionRef={heroRef} />
            <HeroSection
                ref={heroRef}
                title="Nature Restoration with IUCN NL"
                subtitle="A world renowned NGO that is specialized in land acquisition & nature restoration"
                backgroundImage={backgroundImage}
                backgroundPosition="100% 25%"
            />
            {/* <RestorationTempIntroSection /> */}
            {/* <HomepageRestorationSection /> */}
            <RestorationIUCNSection />
            <RestorationQuoteSection />
            <RestorationLandSection />
            <RestorationStatsSection />
            {/* <RestorationProjectsBASection /> */}
            {/* <RestorationProjectsSection /> */}
            <RestorationWashuSection />
            <RestorationCTASection />
        </div>
    );
};

export default RestorationPage;
