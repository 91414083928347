import Web3 from "web3";
import { useEffect, useState } from "react";
import { Contract } from "web3-eth-contract";

interface IAbi {
    constant?: boolean;
    inputs?: Array<{ name: string; type: string; indexed?: boolean }>;
    name?: string;
    outputs?: Array<{ name: string; type: string }>;
    payable?: boolean;
    stateMutability?: "nonpayable" | "payable" | "view" | "pure";
    type?: "function" | "constructor" | "event" | "fallback" | "receive";
    anonymous?: boolean;
}

const useReadOnlyContract = (ABI: IAbi[], address: string): Contract | null => {
    const [contract, setContract] = useState<Contract | null>(null);

    useEffect(() => {
        const loadContract = async () => {
            const alchemyRpcUrl =
                (process.env.REACT_APP_NETWORK === "mainnet"
                    ? process.env.REACT_APP_ALCHEMY_POLYGON_RPC_URL
                    : process.env.REACT_APP_ALCHEMY_MUMBAI_RPC_URL) ||
                "http://default_url_placeholder";

            const provider = new Web3.providers.HttpProvider(alchemyRpcUrl);
            const web3 = new Web3(provider);
            const contractInstance = new web3.eth.Contract(ABI, address);
            setContract(contractInstance);
        };
        loadContract();
    }, [ABI, address]);

    return contract;
};

export default useReadOnlyContract;
