import React from "react";
import Header from "./Header";
import Button from "components/Button";
import { Link } from "react-router-dom";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";

const Congratulations = () => {
    const { width, height } = useWindowSize();
    return (
        <div className="min-h-screen flex flex-col pt-40 relative mb-20">
            <div>
                <Confetti
                    width={width}
                    height={height * 1.5}
                    numberOfPieces={50}
                />
            </div>
            <Header />
            <div className="flex-grow flex justify-center text-left">
                <div className="w-full max-w-2xl px-4 sm:px-0">
                    <h2 className="text-5xl font-heading font-bold mb-6 text-shadow">
                        Congratulations on your bird!
                    </h2>
                    <p className="mb-6 text-xl text-shadow">
                        Thank you for believing in Phauna. You are now the proud
                        owner of one of only 200 photos from Arjan
                        Dwarshuis&apos; and Phauna&apos;s inaugural collection.
                    </p>
                    <h3 className="text-3xl font-heading font-bold mb-6 text-shadow">
                        What&apos;s next?
                    </h3>
                    <ul className="list-disc pl-5 mb-6 text-lg text-shadow space-y-4">
                        <li>
                            Relax and watch your contribution in action. Please
                            remember that nature restoration takes time.
                        </li>
                        <li>
                            Take a look at all the beautifully purchased photos
                            on the{" "}
                            <a
                                href="https://opensea.io/collection/arjans-big-year-genesis"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-tertiary hover:text-accent underline"
                            >
                                OpenSea
                            </a>{" "}
                            marketplace. More information on how to sell or buy
                            will be available here soon.
                        </li>
                        <li>
                            We will be setting up an exclusive email list for
                            all Arjan&apos;s Big Year photo owners.
                        </li>
                        <li>
                            If you purchased with a credit card, you can access
                            or send your photo through{" "}
                            <a
                                href="https://www.crossmint.com/signin"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-tertiary hover:text-accent underline"
                            >
                                Crossmint
                            </a>
                            . Just log in with your email.
                        </li>
                        <li>
                            Lots of stuff will happen on the background. Over
                            the next months, Phauna will be focusing on:
                            <ul className="list-disc pl-5 mb-6 text-lg text-shadow space-y-4">
                                <li>
                                    Building data feeds from the nature reserve
                                    to our Arjan&apos;s Big Year owners.
                                </li>
                                <li>
                                    Developing Arjan&apos;s main collection of
                                    1,500 photos.
                                </li>
                                <li>Building our brand.</li>
                                <li>
                                    Listening to your feedback to offer all bird
                                    owners the best contribution experience.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <Link to="/" className="flex justify-center items-center">
                        <Button className="mt-4">Return to Home</Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Congratulations;
