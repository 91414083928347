import React from "react";

const BuySectionNFTDetails = ({
    name,
    description,
    priceInMatic,
    priceInEth,
    priceInDollars,
    // numberOfNFTs,
    proceedsContribution,
    proceedsArtist,
    proceedsCosts,
    projectName,
    projectDescription,
}) => {
    return (
        <div className="space-y-4">
            <div className="grid grid-cols-3 text-center mb-4">
                <div>
                    <div className="text-m font-semibold mb-2">Price</div>
                    <div className="text-xl font-semibold">
                        {parseInt(priceInMatic).toString()} MATIC
                    </div>
                </div>
                <div>
                    <div className="text-m font-semibold mb-2">
                        Price equivalent
                    </div>
                    <div className="text-xl font-semibold">
                        {priceInEth.toFixed(3)} ETH
                    </div>
                </div>
                <div>
                    <div className="text-m font-semibold mb-2">
                        Price equivalent
                    </div>
                    <div className="text-xl font-semibold">
                        $ {priceInDollars.toFixed(2)}
                    </div>
                </div>
                {/* <div>
                    <div className="text-m font-semibold mb-2">
                        Number of NFTs
                    </div>
                    <div className="text-xl font-semibold">{numberOfNFTs}</div>
                </div> */}
            </div>

            <div className="mt-6">
                <div className="text-lg font-semibold mb-2">{name}</div>
                <p className="text-sm mt-4">{description}</p>
            </div>

            <div className="mt-6">
                <div className="text-lg font-semibold mb-2">
                    Project: {projectName}
                </div>
                <p className="text-sm mt-4">{projectDescription}</p>
            </div>

            <div>
                <div className="text-lg font-semibold mb-2">
                    Proceeds Distribution
                </div>
                <div className="w-full bg-gray-300 overflow-hidden h-6 flex mb-1">
                    <div
                        style={{ width: `${proceedsContribution}%` }}
                        className="bg-green-500"
                    ></div>
                    <div
                        style={{ width: `${proceedsArtist}%` }}
                        className="bg-blue-400"
                    ></div>
                    <div
                        style={{ width: `${proceedsCosts}%` }}
                        className="bg-blue-300"
                    ></div>
                </div>

                <div className="relative w-full">
                    <div
                        className="absolute flex flex-col items-center text-sm font-semibold"
                        style={{
                            left: `${proceedsContribution / 2}%`,
                            transform: "translateX(-50%)",
                        }}
                    >
                        <div>Contribution</div>
                        <div>{proceedsContribution}%</div>
                    </div>
                    <div
                        className="absolute flex flex-col items-center text-sm font-semibold"
                        style={{
                            left: `${
                                proceedsContribution + proceedsArtist / 2
                            }%`,
                            transform: "translateX(-50%)",
                        }}
                    >
                        <div>Artist</div>
                        <div>{proceedsArtist}%</div>
                    </div>
                    <div
                        className="absolute flex flex-col items-center text-sm font-semibold"
                        style={{
                            left: `${
                                proceedsContribution +
                                proceedsArtist +
                                proceedsCosts / 2
                            }%`,
                            transform: "translateX(-50%)",
                        }}
                    >
                        <div>Costs</div>
                        <div>{proceedsCosts}%</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BuySectionNFTDetails;
