import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import image1 from "../../../assets/images/timothy-k-unsplash.webp";
import image2 from "../../../assets/images/countries-unsplash.avif";
import image3 from "../../../assets/images/raccoons-unsplash.avif";

const HomepageStepsSection = () => {
    const steps = [
        {
            title: "135",
            number: "Projects funded",
            image: image1,
        },
        {
            title: "39",
            number: "Countries",
            image: image2,
        },
        {
            title: "70,000",
            number: "Hectares Protected",
            image: image3,
        },
    ];

    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

    return (
        <section className="content-section py-20">
            <div className="container mx-auto px-4">
                <div className="font-heading grid grid-cols-1 md:grid-cols-3 gap-12 items-center text-center text-shadow">
                    {steps.map((step, index) => (
                        <div
                            key={index}
                            data-aos="fade-up"
                            data-aos-delay={index * 200}
                            className="step"
                        >
                            <div
                                className="w-36 h-36 md:w-40 md:h-40 mx-auto mb-12 bg-cover bg-center rounded-full shadow-2xl"
                                style={{
                                    backgroundImage: `url(${step.image})`,
                                }}
                            ></div>
                            <h3 className="text-white text-2xl md:text-2xl font-bold">
                                {step.number}
                            </h3>
                            <h3 className="text-white text-3xl md:text-4xl font-bold mt-2">
                                {step.title}
                            </h3>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default HomepageStepsSection;
