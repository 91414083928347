import React, { useEffect } from "react";
import "../../../App.css";
// import Logo1 from "../../../assets/images/logos/logo_amdax.png";
// import Logo2 from "../../../assets/images/logos/logo_iucnnl.png";
// import Logo3 from "../../../assets/images/logos/logo_klimadao.png";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const HomepageOrganizations = () => {
    useEffect(() => {
        AOS.refresh();
    }, []);

    return (
        <section> </section>
        // <section className="content-section py-20">
        //     <div className="container mx-auto px-4 text-center text-shadow text-white">
        //         <h2
        //             className="font-heading text-4xl md:text-6xl mb-10"
        //             data-aos="fade-down"
        //             data-aos-duration="800"
        //         >
        //             Partners with
        //         </h2>
        //         <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
        //             <img
        //                 src={Logo1}
        //                 alt="Partner 1"
        //                 className="w-48 h-24 object-cover mx-auto"
        //                 data-aos="fade-up"
        //                 data-aos-duration="800"
        //             />

        //             <img
        //                 src={Logo2}
        //                 alt="Partner 2"
        //                 className="w-64 h-26 object-cover mx-auto"
        //                 data-aos="fade-up"
        //                 data-aos-duration="800"
        //                 data-aos-delay="200"
        //             />

        //             <img
        //                 src={Logo3}
        //                 alt="Partner 3"
        //                 className="w-48 h-24 object-cover mx-auto"
        //                 data-aos="fade-up"
        //                 data-aos-duration="800"
        //                 data-aos-delay="400"
        //             />
        //         </div>
        //     </div>
        // </section>
    );
};

export default HomepageOrganizations;
