import React, { useState } from "react";
import BuySectionNumberNFTs from "./BuySectionNumberNFTs";
import BuySectionPaymentOptions from "./BuySectionPaymentOptions";
import BuySectionCheckout from "./BuySectionCheckout";

const BuySectionMintSection = ({
    dollarPricePerNFT,
    maticPricePerNFT,
    ethPricePerNFT,
    isMintingActive,
    isSoldOut,
}) => {
    const [paymentOption, setPaymentOption] = useState("creditcard");
    const [networkOption, setNetworkOption] = useState("polygon");
    const [nftCount, setNftCount] = useState("1");

    return (
        <div>
            <h2 className="text-3xl font-heading mb-4">Purchase your NFT</h2>
            <BuySectionNumberNFTs
                nftCount={nftCount}
                setNftCount={setNftCount}
            />
            <BuySectionPaymentOptions
                paymentOption={paymentOption}
                setPaymentOption={setPaymentOption}
                networkOption={networkOption}
                setNetworkOption={setNetworkOption}
            />
            <BuySectionCheckout
                paymentOption={paymentOption}
                networkOption={networkOption}
                nftCount={nftCount}
                dollarPricePerNFT={dollarPricePerNFT}
                maticPricePerNFT={maticPricePerNFT}
                ethPricePerNFT={ethPricePerNFT}
                isMintingActive={isMintingActive}
                isSoldOut={isSoldOut}
            />
        </div>
    );
};

export default BuySectionMintSection;
