import React from "react";

import BuySectionImage from "./minting_card_components/BuySectionImage";
import BuySectionNFTDetails from "./minting_card_components/BuySectionNFTDetails";
import BuySectionMintSection from "./minting_card_components/BuySectionMintSection";
import BuySectionProgressBar from "./minting_card_components/BuySectionProgressBar";

const MintingCard = ({ collection }) => {
    return (
        <div className="rounded-lg max-w-7xl mx-auto px-8 mt-12 mb-12">
            <div className="text-center font-heading text-5xl py-4">
                {collection.nft.name}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-5 gap-y-12 md:gap-x-32 relative">
                <div className="hidden md:block absolute inset-0 gridWithDivider"></div>
                <div className="md:col-start-1 md:col-span-5">
                    <BuySectionProgressBar
                        progress={collection.progressbar.progress}
                    />
                </div>

                <div className="row-start-3 md:col-span-3 md:row-start-2 flex flex-col">
                    <div className="h-44 w-full rounded-lg overflow-hidden">
                        <BuySectionImage src={collection.nft.image} />
                    </div>
                    <div className="w-full py-4 px-6 font-body">
                        <BuySectionNFTDetails
                            name={collection.nft.name}
                            description={collection.nft.description}
                            priceInMatic={collection.nft.priceInMatic}
                            priceInEth={collection.nft.priceInEth}
                            priceInDollars={collection.nft.priceInDollars}
                            numberOfNFTs={collection.nft.totalNFTs}
                            proceedsContribution={
                                collection.nft.proceedsContribution
                            }
                            proceedsArtist={collection.nft.proceedsArtist}
                            proceedsCosts={collection.nft.proceedsCosts}
                            projectName={collection.project.name}
                            projectDescription={collection.project.description}
                        />
                    </div>
                </div>

                <div className="row-start-2 md:col-start-4 md:col-span-2 md:row-start-2 md:row-span-2">
                    <BuySectionMintSection
                        dollarPricePerNFT={collection.nft.priceInDollars}
                        maticPricePerNFT={collection.nft.priceInMatic}
                        ethPricePerNFT={collection.nft.priceInEth}
                        isMintingActive={collection.nft.isMintingActive}
                        isSoldOut={collection.nft.isSoldOut}
                    />
                </div>
            </div>
        </div>
    );
};

export default MintingCard;
