import React, { useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import AppRoutes from "./AppRoutes";
import ErrorNotification from "./components/ErrorNotification.tsx";
import { useWeb3Connection } from "./hooks/useWeb3Connection.ts";
import ScrollToTop from "./components/ScrollToTop";
import ReactGA from "react-ga";

ReactGA.initialize(process.env.REACT_APP_GOOGLEANALYTICS_ID);

function PageTracker() {
    const location = useLocation();

    useEffect(() => {
        if (window._hsq) {
            window._hsq.push(["setPath", location.pathname]);
            window._hsq.push(["trackPageView"]);
        }
    }, [location]);

    return null;
}

function App() {
    const { connectError } = useWeb3Connection();

    return (
        <Router>
            <PageTracker />
            <ScrollToTop>
                <div className="font-body global-text-colors min-h-screen">
                    <main>
                        {connectError && (
                            <ErrorNotification message={connectError} />
                        )}
                        <AppRoutes />
                    </main>
                    <Footer />
                </div>
            </ScrollToTop>
        </Router>
    );
}

export default App;
