import React, { useRef } from "react";
import Header from "../Header";
import HeroSection from "./HeroSection";
import FAQContentSection from "./faq_sections/FAQContentSection";
import backgroundImage from "../../assets/images/lemur.jpg";

const FAQPage = () => {
    const heroRef = useRef();

    return (
        <div className="gradient-bg min-h-screen">
            <Header sectionRef={heroRef} />
            <HeroSection
                ref={heroRef}
                title="Frequently Asked Questions"
                subtitle=""
                backgroundImage={backgroundImage}
                backgroundPosition="center 35%"
            />
            <FAQContentSection />
        </div>
    );
};

export default FAQPage;
