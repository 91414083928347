import React from "react";
import backgroundImage from "./../../../assets/images/Barred-Owlet-Nightjar-compressed.webp";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const CollectionQuoteSection = () => {
    const quote =
        "Phauna's NFTs combine my love for birds and conservation, empowering us to restore ecosystems together.";
    const author = "Arjan Dwarshuis";

    return (
        <section className="relative font-heading text-shadow py-20">
            <div
                className="w-full h-72 md:h-80 shadow-2xl"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center 53%",
                    backgroundRepeat: "no-repeat",
                }}
            >
                <div className="container mx-auto px-4 h-full flex items-center">
                    <div className="text-right w-full mr-8 md:mr-12">
                        <p
                            className="text-2xl sm:text-5xl text-white italic leading-relaxed mb-4 text-shadow"
                            data-aos="fade-left"
                            data-aos-duration="800"
                            style={{
                                textShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
                            }}
                        >
                            &ldquo;{quote}&rdquo;
                        </p>
                        <p
                            className="text-xl sm:text-4xl text-white font-bold"
                            data-aos="fade-left"
                            data-aos-duration="800"
                            data-aos-delay="300"
                            style={{
                                textShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
                            }}
                        >
                            {author}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CollectionQuoteSection;
