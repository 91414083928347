import React from "react";
import useContract from "../hooks/useContract";
import { useWeb3Connection } from "../hooks/useWeb3Connection";
import Web3 from "web3";
import { InjectedConnector } from "@web3-react/injected-connector";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

interface CheckoutButtonProps {
    contractAddress: string;
    numberOfNFTs: number;
    price: number; // price in Ether
    network: "ethereum" | "polygon";
    overrideStyle?: string;
    proceedEnabled: boolean;
}

const CheckoutButton: React.FC<CheckoutButtonProps> = ({
    contractAddress,
    numberOfNFTs,
    price,
    network,
    overrideStyle,
    proceedEnabled,
}) => {
    const supportedChainIds =
        network === "ethereum" ? [1, 3, 4, 5, 42] : [137, 80001];
    const connector = new InjectedConnector({ supportedChainIds });

    const { web3, account, connectToProvider } = useWeb3Connection(
        connector,
        network
    );

    const contract = useContract(web3, contractAddress);

    const navigate = useNavigate();

    const handleCheckout = async () => {
        if (!web3 || !account) {
            await connectToProvider();
            if (!web3 || !account) {
                alert("Please connect your wallet first.");
                return;
            }
        }

        if (!contract) {
            alert("Contract is not loaded.");
            return;
        }

        try {
            const priceInWei = Web3.utils.toWei(price, "ether");
            await contract.mintNFTs(numberOfNFTs, account, {
                value: priceInWei,
            });

            // On successful purchase, navigate to the congratulations page
            navigate("/congratulations");
        } catch (error) {
            console.error("Error buying NFT:", error);
            alert("Failed to buy NFT. Please try again.");
        }
    };

    return (
        <Button
            onClick={proceedEnabled ? handleCheckout : undefined}
            overrideStyle={overrideStyle}
            disabled={!proceedEnabled}
            title={!proceedEnabled ? "Please connect your wallet first" : ""}
            proceedEnabled={proceedEnabled}
        >
            Checkout
        </Button>
    );
};

export default CheckoutButton;
