import React from "react";
import backgroundImage from "./../../../assets/images/Southern-Silvery-Kingfisher-compressed.JPG";
import Button from "../../Button";

const HomepageCommunitySection = () => {
    return (
        <section className="relative py-20">
            <div
                className="w-full h-72 md:h-80 bg-container-community-cta shadow-2xl"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                }}
            >
                <div className="container mx-auto px-4 h-full flex items-center">
                    <div className="text-white w-full ml-8 md:ml-12">
                        <h2
                            className="font-heading text-3xl md:text-5xl mb-6 text-shadow"
                            data-aos="fade-right"
                            data-aos-duration="800"
                        >
                            Witness restoration
                        </h2>
                        <div className="flex space-x-8 sm:space-x-10 text-4xl md:text-5xl">
                            <Button
                                href="https://discord.gg/Hz4rKhRB"
                                target="_blank"
                                rel="noreferrer"
                                className="text-xl sm:text-base md:text-lg lg:text-2xl font-heading px-4 sm:px-6 py-2 sm:py-3 transition-all duration-300"
                                data-aos="fade-up"
                                data-aos-duration="800"
                                data-aos-delay="400"
                            >
                                Join our Discord
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomepageCommunitySection;
