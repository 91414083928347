import React, { forwardRef } from "react";

const HeroSection = forwardRef(
    (
        { title, subtitle, backgroundImage, backgroundPosition = "100% 60%" },
        ref
    ) => {
        const sectionStyle = {
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "100%",
            backgroundPosition,
        };

        return (
            <div
                ref={ref}
                className="relative h-[250px] md:h-[350px] overflow-hidden shadow-2xl"
                style={sectionStyle}
            >
                <div
                    style={{
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)",
                        maxWidth: "1480px",
                        height: "130%",
                    }}
                    className="flex flex-col justify-center md:w-[76%] w-[80%]"
                >
                    <h1
                        style={{ maxWidth: "85%" }}
                        className="text-4xl sm:text-6xl text-white font-heading text-shadow hero-title"
                    >
                        {title}
                    </h1>
                    <p className="text-2xl sm:text-4xl text-white mt-4 font-heading text-shadow hero-title">
                        {subtitle}
                    </p>
                </div>
            </div>
        );
    }
);

HeroSection.displayName = "HeroSection";

export default HeroSection;
