import { useState, useEffect } from "react";
import { Contract } from "@ethersproject/contracts";
import contractAbi from "../MintNFTsArjanAbi.json";

const useContract = (web3, contractAddress) => {
    const [contract, setContract] = useState(null);

    useEffect(() => {
        if (web3 && contractAddress) {
            const newContract = new Contract(
                contractAddress,
                contractAbi,
                web3.getSigner()
            );
            setContract(newContract);
        }
    }, [web3, contractAddress]);

    return contract;
};

export default useContract;
