import React from "react";
import Button from "components/Button";
import iucn_monkey from "../../../assets/images/(4)_PAN_CREA_SPN_2.jpeg";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const IUCNIntroSection = () => {
    return (
        <section className="content-container mt-12 sm:mt-12 sm:py-8 md:py-8 lg:py-8">
            <div className="container mx-auto px-4 ">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
                    <div
                        className="order-2 md:order-1 w-full md:w-2/3 mx-auto"
                        data-aos="fade-right"
                        data-aos-duration="800"
                    >
                        <div className="image-copyright-container">
                            <img
                                src={iucn_monkey}
                                alt="IUCN-NL Related"
                                className="w-full h-auto rounded-full shadow-2xl"
                            />
                            <div className="image-copyright-overlay">
                                © Michael Roy
                            </div>
                        </div>
                        <div className="py-12 mt-12 -mb-12 text-center">
                            <Button
                                href="https://www.iucn.nl/en"
                                target="_blank"
                                rel="noreferrer"
                                className="font-heading text-xl"
                            >
                                Visit IUCN NL Website
                            </Button>
                        </div>
                    </div>
                    <div
                        className="order-1 md:order-2 text-white"
                        data-aos="fade-left"
                        data-aos-duration="800"
                    >
                        <h2 className="text-4xl md:text-5xl font-heading mb-6 text-shadow">
                            Introducing IUCN NL
                        </h2>
                        <p className="text-sm md:text-base mb-6">
                            IUCN NL is the Dutch Committee of the International
                            Union for Conservation of Nature (IUCN), the
                            world&apos;s largest and most diverse environmental
                            network. Founded in 1948, IUCN is a global authority
                            on the status of nature and the measures needed to
                            safeguard it.
                        </p>
                        <p className="text-sm md:text-base mb-6">
                            Applying an integrated conservation approach, IUCN
                            NL works together with partner organizations around
                            the globe to safeguard nature as the foundation for
                            all life on earth.
                        </p>
                        <p className="text-sm md:text-base mb-6">
                            The Land Acquisition Fund of IUCN NL contributes to
                            expanding, connecting and safeguarding habitats for
                            endangered species. For more than 20 years the fund
                            has been supporting nature organizations all over
                            the world to purchase endangered natural habitats.
                        </p>
                        <p className="text-sm md:text-base mb-6">
                            IUCN NL&apos;s has an extensive experience, wide
                            network of partner organizations, and a solid
                            reputation for delivering results. By collaborating
                            with IUCN NL on our NFT project, we ensure the
                            highest level of trust, quality, and commitment to
                            protecting nature.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default IUCNIntroSection;
