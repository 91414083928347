import React, { useEffect, useState, useRef, useCallback } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/icons/phauna_logo_zoveel.svg";
import { FaTwitter, FaDiscord, FaInstagram } from "react-icons/fa";
import Hamburger from "hamburger-react";
import Navbar from "./Navbar";

const Header = ({ sectionRef }) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownHideTimeout = useRef(null);
    const [navbarOpen, setNavbarOpen] = useState(false);

    const showDropdown = () => {
        clearTimeout(dropdownHideTimeout.current);
        setDropdownOpen(true);
    };

    const hideDropdown = () => {
        dropdownHideTimeout.current = setTimeout(() => {
            setDropdownOpen(false);
        }, 300);
    };

    const handleScroll = useCallback(() => {
        const currentScrollY = window.scrollY;
        const heroSectionHeight = sectionRef?.current?.offsetHeight || 0;
        setIsScrolled(currentScrollY >= heroSectionHeight);
    }, [sectionRef]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [handleScroll]);

    return (
        <header
            className={`${
                isScrolled
                    ? "gradient-bg header-shadow"
                    : "transparent-header shadow-none"
            } fixed top-0 left-0 w-full text-white py-4 shadow-md z-50 transition-all duration-300 text-shadow`}
        >
            <div className="container mx-auto px-4 sm:w-4/5">
                <div className="flex items-center justify-between ">
                    <div className="font-heading text-2xl flex">
                        <NavLink to="/">
                            <img
                                src={Logo}
                                alt="Phauna Logo"
                                className="h-8 ml-4 mr-5 hover:opacity-70 transition-opacity duration-200 "
                            />
                        </NavLink>
                    </div>

                    <div className="hidden sm:flex space-x-6 items-center">
                        <a
                            href="https://twitter.com/Phauna_earth"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-2xl hover:text-tertiary transition-all duration-200 text-shadow"
                        >
                            <FaTwitter />
                        </a>
                        <a
                            href={process.env.REACT_APP_DISCORD_INVITE}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-2xl  hover:text-tertiary transition-all duration-200 text-shadow"
                        >
                            <FaDiscord />
                        </a>
                        <a
                            href="https://instagram.com/phauna.earth"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-2xl  hover:text-tertiary transition-all duration-200 text-shadow"
                        >
                            <FaInstagram />
                        </a>
                    </div>

                    <div className="hidden sm:block">
                        <Navbar
                            dropdownOpen={dropdownOpen}
                            showDropdown={showDropdown}
                            hideDropdown={hideDropdown}
                            setDropdownOpen={setDropdownOpen}
                        />
                    </div>

                    <div className="flex space-x-4 items-center sm:hidden">
                        <div className="flex space-x-2">
                            <a
                                href="https://twitter.com/Phauna_earth"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-2xl hover:text-tertiary transition-all duration-200 text-shado"
                            >
                                <FaTwitter />
                            </a>
                            <a
                                href="https://discord.gg/Hz4rKhRB"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-2xl  hover:text-tertiary transition-all duration-200 text-shado"
                            >
                                <FaDiscord />
                            </a>
                            <a
                                href="https://instagram.com/phauna.earth"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-2xl  hover:text-tertiary transition-all duration-200 text-shadow"
                            >
                                <FaInstagram />
                            </a>
                        </div>
                        <a
                            className="block relative"
                            onClick={() => setNavbarOpen(!navbarOpen)}
                        >
                            <Hamburger
                                toggled={navbarOpen}
                                toggle={setNavbarOpen}
                            />
                        </a>
                    </div>
                </div>

                {navbarOpen && (
                    <div className="sm:hidden gradient-bg2 p-4 rounded-3xl mt-20 shadow-lg flex flex-col absolute top-0 right-4 w-30 pt-8 transition-all duration-300 ease-in-out">
                        <Navbar
                            mobile
                            dropdownOpen={dropdownOpen}
                            showDropdown={showDropdown}
                            hideDropdown={hideDropdown}
                            setIsHamburgerOpen={setNavbarOpen}
                        />
                    </div>
                )}
            </div>
        </header>
    );
};

export default Header;
