import React, { useRef } from "react";
import Header from "../Header";
import HeroSection from "./HeroSection";
import AboutCompanySection from "./about_sections/AboutCompanySection";
import backgroundImage from "../../assets/images/phauna_forest.webp";

const AboutPage = () => {
    const heroRef = useRef();

    return (
        <div className="gradient-bg min-h-screen about-page">
            <Header sectionRef={heroRef} />
            <HeroSection
                ref={heroRef}
                title="We are Phauna"
                subtitle="Art that restores nature and let you stay connected"
                backgroundImage={backgroundImage}
                backgroundPosition="100% 25%"
            />
            <AboutCompanySection />
        </div>
    );
};

export default AboutPage;
