import React, { useState } from "react";
import { useWeb3Connection } from "../hooks/useWeb3Connection";
import { injectedConnector, walletConnectConnector } from "../connectors";
import metamaskLogo from "../assets/images/logos/logo_metamask.png";
// import walletConnectLogo from "../assets/images/logos/logo_walletconnect.png";
import { Web3Connection } from "../hooks/useWeb3Connection";
import Button from "./Button";

interface ConnectWalletButtonProps {
    onConnect: () => void;
    onDisconnect: () => void;
    network: "ethereum" | "polygon";
}

const ConnectWalletButton: React.FC<ConnectWalletButtonProps> = ({
    onConnect,
    onDisconnect,
    network,
}) => {
    const { connectError: injectedConnectError, ...injectedWeb3 } =
        useWeb3Connection(injectedConnector, network);
    const { connectError: walletConnectError, ...walletConnectWeb3 } =
        useWeb3Connection(walletConnectConnector, network);

    const networkName = (network) => {
        switch (network) {
            case "ethereum":
                return "Ethereum";
            case "polygon":
                return "Polygon";
            default:
                return "the correct";
        }
    };

    const handleConnectWallet = async (connector: Web3Connection) => {
        try {
            await connector.connectToProvider();
            onConnect();
        } catch (err) {
            console.error(err);
        }
    };

    const handleDisconnectWallet = async (connector: Web3Connection) => {
        try {
            await connector.disconnectWallet();
            onDisconnect();
        } catch (err) {
            console.error(err);
        }
    };

    const [showModal, setShowModal] = useState(false);

    const closeModal = () => {
        setShowModal(false);
    };

    const isConnected =
        injectedWeb3.isConnected || walletConnectWeb3.isConnected;
    const account = injectedWeb3.account || walletConnectWeb3.account;

    return (
        <div>
            {isConnected ? (
                <Button
                    onClick={() => handleDisconnectWallet(injectedWeb3)}
                    overrideStyle="w-full text-center py-1 font-heading text-shadow text-xl rounded-full flex items-center justify-center bg-accent text-white"
                >
                    {account
                        ? `${account.slice(0, 6)}...${account.slice(-4)}`
                        : "Connected"}
                </Button>
            ) : (
                <>
                    <Button
                        onClick={() => setShowModal(true)}
                        overrideStyle="w-full text-center py-1 font-heading text-shadow text-xl rounded-full flex items-center justify-center bg-secondary"
                    >
                        Connect Wallet
                    </Button>

                    {showModal && (
                        <div
                            onClick={closeModal}
                            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
                        >
                            <div
                                onClick={(e) => e.stopPropagation()}
                                className="gradient-bg2 rounded-lg shadow-md p-8"
                            >
                                <Button
                                    onClick={() =>
                                        handleConnectWallet(injectedWeb3)
                                    }
                                    overrideStyle="flex justify-center items-center min-w-[300px] text-center py-1 font-heading text-shadow text-xl rounded-full bg-secondary"
                                >
                                    <img
                                        src={metamaskLogo}
                                        alt="MetaMask"
                                        className="w-6 h-6 mr-2"
                                    />
                                    MetaMask
                                </Button>

                                {(injectedConnectError || walletConnectError) &&
                                    !isConnected && (
                                        <p className="text-red-500 text-center mt-2">
                                            {`Please switch to ${networkName(
                                                network
                                            )} network.`}
                                        </p>
                                    )}

                                {/* <Button
                                    onClick={() =>
                                        handleConnectWallet(walletConnectWeb3)
                                    }
                                    overrideStyle="flex justify-center items-center min-w-[300px] text-center py-1 font-heading text-shadow text-xl rounded-full bg-secondary mt-8"
                                >
                                    <img
                                        src={walletConnectLogo}
                                        alt="WalletConnect"
                                        className="w-6 h-6 mr-2 "
                                    />
                                    WalletConnect
                                </Button> */}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ConnectWalletButton;
