import React from "react";
import Button from "components/Button";
import iucn_land from "../../../assets/images/(21)_COL_PROTITI_SPN_2.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const RestorationLandSection = () => {
    return (
        <section className="content-container py-8">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
                    <div
                        className="text-white"
                        data-aos="fade-left"
                        data-aos-duration="800"
                    >
                        <h2 className="text-4xl md:text-5xl font-heading mb-6 text-shadow">
                            Land Acquisition with IUCN NL
                        </h2>
                        <p className="text-sm md:text-base mb-6">
                            Since its inception in 2001, the IUCN NL&apos;s Land
                            Acquisition Fund has been a pivotal force in the
                            preservation, connection, and rejuvenation of
                            habitats for species at risk. By joining forces with
                            local NGOs across the globe, the fund has
                            successfully safeguarded more than 70,000 hectares
                            of natural environments.
                        </p>
                        <p className="text-sm md:text-base mb-6">
                            Our strength lies in our partnerships - with those
                            who possess an unquenchable passion for
                            conservation, the &apos;fire in the belly&apos;.
                            Through these collaborations, we bridge gaps in
                            habitats, reestablish critical migratory paths, and
                            create a substantial impact on a multitude of
                            species.
                        </p>
                        <p className="text-sm md:text-base mb-6">
                            In a pioneering collaboration with the IUCN NL Land
                            Acquisition Fund, Phauna stands at the forefront of
                            conservation efforts, offering transparency and
                            concrete contributions. The proceeds from Phauna are
                            channeled directly into targeted restoration
                            programs, promising a profound and lasting impact on
                            our planet&apos;s ecosystems.
                        </p>
                    </div>
                    <div
                        className="w-full md:w-2/3 mx-auto"
                        data-aos="fade-right"
                        data-aos-duration="800"
                    >
                        <div className="image-copyright-container relative w-full h-64 rounded-full overflow-hidden shadow-2xl">
                            <img
                                src={iucn_land}
                                alt="IUCN Land Acquisition Fund"
                                className="absolute w-full h-full object-cover rounded-full"
                                style={{ objectPosition: "50% 20%" }}
                            />
                            <div className="image-copyright-overlay">
                                © Lisa Hoffner
                            </div>
                        </div>
                        <div className="py-16 mt-4 -mb-12 text-center">
                            {" "}
                            <Button
                                as="a"
                                href="https://www.iucn.nl/en/project/land-acquisition-fund/"
                                target="_blank"
                                rel="noreferrer"
                                className="font-heading text-xl"
                            >
                                Learn More About the Fund
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RestorationLandSection;
