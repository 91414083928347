import React, { useRef } from "react";
import Header from "../Header";
import HeroSection from "./HeroSection";
import backgroundImage from "../../assets/images/Chestnut-crested_Cotinga.jpg";
import GenesisMintSection from "./buy_sections/GenesisMintSection";

const BuyPage = () => {
    const heroRef = useRef();

    return (
        <div className="gradient-bg min-h-screen about-page">
            <Header sectionRef={heroRef} />
            <HeroSection
                ref={heroRef}
                title="Claim your digital nature photos"
                subtitle="Each purchase contributes directly to nature restoration"
                backgroundImage={backgroundImage}
                backgroundPosition="100% 42%"
            />
            <GenesisMintSection />
        </div>
    );
};

export default BuyPage;
