import React from "react";

const BuySectionProgressBar = ({ progress }) => {
    const dollarGoal = 14000;
    let dollarsRaised, formattedDollarsRaised, progress_pct;

    // show no progress for testing in production.
    if (progress < 3) {
        dollarsRaised = 0;
        formattedDollarsRaised = 0;
        progress_pct = 0;
        progress = 0;
    } else {
        dollarsRaised = (progress / 200) * dollarGoal;
        formattedDollarsRaised = new Intl.NumberFormat().format(
            dollarsRaised.toFixed(0)
        );
        progress_pct = (parseInt(progress, 10) / 200) * 100;
    }

    return (
        <div className="w-full py-2">
            <div className="flex justify-between items-center mb-2">
                <p className="font-semibold">Funding Meter</p>{" "}
                <p className="font-semibold">{progress} / 200 NFTs sold</p>
            </div>
            <div className="w-full h-6 bg-gray-200 rounded shadow">
                <div
                    className="h-full text-center text-m text-white bg-green-500 rounded text-shadow"
                    style={{ width: `${progress_pct}%` }}
                >
                    {progress_pct.toFixed(0)}%
                </div>
            </div>
            <div className="flex justify-between items-center mt-2">
                <p className="font-semibold"> </p>
                <p className="font-semibold">
                    ${formattedDollarsRaised} / $14,000 raised
                </p>
            </div>
        </div>
    );
};

export default BuySectionProgressBar;
