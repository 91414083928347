import React from "react";

const BackgroundVideo = () => {
    return (
        <div
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                overflow: "hidden",
                zIndex: 0,
            }}
        >
            <iframe
                title="background-video"
                src="https://customer-imi786yi73tb2683.cloudflarestream.com/d32916d6094548840d43ef8ae3f8d249/iframe?muted=true&preload=true&loop=true&autoplay=true&poster=https%3A%2F%2Fcustomer-imi786yi73tb2683.cloudflarestream.com%2Fd32916d6094548840d43ef8ae3f8d249%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
                style={{
                    border: "none",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    minHeight: "200%",
                    minWidth: "177.77vh", // 16:9 Aspect Ratio
                    width: "100%",
                    height: "auto",
                }}
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowFullScreen
            />
        </div>
    );
};

export default BackgroundVideo;
