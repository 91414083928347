import React from "react";
import { Link } from "react-router-dom";
import Button from "./../../Button";
import introImage from "../../../assets/images/monkey.webp";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const HomepageIntroductionSection = () => {
    return (
        <section className="content-section gradient-bg mt-12 sm:mt-0 sm:py-8 md:py-8 lg:py-8">
            <div className="container mx-auto px-4 sm:px-8">
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-8 sm:gap-12 md:gap-8 lg:gap-12 items-center">
                    <div
                        className="w-3/4 sm:w-2/3 md:w-2/3 mx-auto"
                        data-aos="fade-right"
                        data-aos-duration="800"
                    >
                        <img
                            src={introImage}
                            alt="Introduction"
                            className="w-full h-auto rounded-full shadow-2xl "
                        />
                    </div>
                    <div
                        className="text-white p-12 sm:pt-12"
                        data-aos="fade-left"
                        data-aos-duration="800"
                    >
                        <h2 className="text-3xl sm:text-3xl md:text-4xl lg:text-5xl font-heading mb-6 sm:mb-6 md:mb-6 lg:mb-5 text-shadow">
                            Welcome to Phauna
                        </h2>
                        <p className="text-text-base md:text-base lg:text-lg mb-6 sm:mb-6 md:mb-4 lg:mb-5 text-shadow">
                            We believe that contributing to nature should be an
                            enjoyable and fulfilling journey.
                        </p>
                        <p className="text-text-base md:text-base lg:text-lg mb-6 sm:mb-6 md:mb-4 lg:mb-5 text-shadow">
                            Our platform brings together art, nature, and
                            technology in a harmonious union.
                        </p>
                        <p className="text-text-base md:text-base lg:text-lg mb-6 sm:mb-6 md:mb-4 lg:mb-5 text-shadow">
                            Explore our inaugural photography collection,
                            featuring the captivating works of renowned birder
                            Arjan Dwarshuis.
                        </p>
                        <p className="text-text-base md:text-base lg:text-lg mb-6 sm:mb-6 md:mb-4 lg:mb-5 text-shadow">
                            With 70% of your contributions directly supporting
                            IUCN NL, a trusted global NGO specializing in nature
                            restoration, you can be confident that your
                            involvement with Phauna makes a meaningful
                            difference.
                        </p>
                        <p className="text-text-base md:text-base lg:text-lg mb-6 sm:mb-6 md:mb-4 lg:mb-5 text-shadow"></p>

                        <div className="pt-6 sm:pt-4">
                            <Link to="/about">
                                <Button className="font-heading text-lg sm:text-base md:text-lg lg:text-xl">
                                    Learn more
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomepageIntroductionSection;
