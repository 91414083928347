import React from "react";
import Button from "../../../Button";
import { FaCreditCard, FaEthereum } from "react-icons/fa";
import PolygonLogo from "../../../../assets/icons/polygon-matic-logo.svg";

const BuySectionPaymentOptions = ({
    paymentOption,
    setPaymentOption,
    setNetworkOption,
}) => {
    const handlePaymentOptionChange = (payment) => {
        setPaymentOption(payment);
        if (payment === "ethereum") {
            setNetworkOption("ethereum");
        } else if (payment === "matic") {
            setNetworkOption("polygon");
        }
    };

    return (
        <div className="mt-6">
            <span className="block mb-2">Choose payment method:</span>
            <div className="space-y-2">
                <div className="flex space-x-2 py-1">
                    <Button
                        onClick={() => handlePaymentOptionChange("creditcard")}
                        overrideStyle={`w-full text-center py-1 font-heading text-shadow text-xl rounded-full flex items-center justify-center ${
                            paymentOption === "creditcard"
                                ? "bg-accent text-white"
                                : "bg-secondary"
                        }`}
                    >
                        <FaCreditCard className="mr-1 sm:mr-2 align-middle text-shadow w-4 sm:w-5" />
                        <span className="relative top-1 mb-1">Credit card</span>
                    </Button>

                    <Button
                        onClick={() => handlePaymentOptionChange("ethereum")}
                        overrideStyle={`w-full text-center uppercasepy-1 font-heading text-shadow text-xl rounded-full flex items-center justify-center ${
                            paymentOption === "ethereum"
                                ? "bg-accent text-white"
                                : "bg-secondary"
                        }`}
                    >
                        <FaEthereum className="sm:mr-1 align-middle text-shadow" />
                        <span className="relative top-1 mb-1">Ethereum</span>
                    </Button>

                    <Button
                        onClick={() => handlePaymentOptionChange("matic")}
                        overrideStyle={`w-full text-center uppercasepy-1 font-heading text-shadow text-xl rounded-full flex items-center justify-center ${
                            paymentOption === "matic"
                                ? "bg-accent text-white"
                                : "bg-secondary"
                        }`}
                    >
                        <img
                            src={PolygonLogo}
                            alt="Matic"
                            className="mr-1 sm:mr-2 align-middle w-5 h-5"
                        />
                        <span className="relative top-1 mb-1">Matic</span>
                    </Button>
                </div>
                {paymentOption === "creditcard" && (
                    <div className="text-sm py-2 text-shadow">
                        {" "}
                        <div className="font-bold">Please note that:</div>
                        <ul
                            className="py-1 mt-2 "
                            style={{ listStyleType: "disc" }}
                        >
                            <li className="mb-2">
                                Crossmint manages payment and NFT storage
                            </li>
                            <li className="mb-2">
                                Your credit card data is securely processed by
                                Stripe
                            </li>
                            <li className="mt-2 mb-2">
                                Dollar price at checkout different due
                                transaction fees and fluctuations of set price
                                in Matic
                            </li>
                            <li className="mt-2 mb-2">
                                You can access your NFT with email
                            </li>
                            <li className="mt-2">
                                Transfer your NFT from Crossmint to a wallet at
                                convenience
                            </li>
                        </ul>
                    </div>
                )}
                {paymentOption === "ethereum" && (
                    <div className="text-sm py-2 text-shadow">
                        {" "}
                        Please note that:
                        <ul
                            className="py-1 mt-2"
                            style={{ listStyleType: "disc" }}
                        >
                            <li className="mt-2 mb-2">
                                Crossmint manages this cross-chain payment
                            </li>
                            <li className=" mb-2">
                                Eth price varies due to fluctuations of set
                                price in Matic
                            </li>
                            <li className="mt-2 mb-2">
                                Connect your wallet to the Ethereum network
                            </li>
                            <li className="mt-2">
                                NFTs can be sent directly to your Polygon wallet
                            </li>
                        </ul>
                    </div>
                )}
                {paymentOption === "matic" && (
                    <div className="text-sm py-2 text-shadow">
                        {" "}
                        Please note that:
                        <ul
                            className="py-1 mt-2"
                            style={{ listStyleType: "disc" }}
                        >
                            <li className="mb-2">
                                Connect your wallet to the Polygon network
                            </li>
                            <li className="mt-2">
                                The NFTs will be sent directly to your wallet
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BuySectionPaymentOptions;
