import React, { forwardRef } from "react";

import BackgroundVideo from "./HomepageBackgroundVideo";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const HomepageHeroSection = forwardRef((_, ref) => {
    const heroTitle = "Photography that restores nature";
    const heroSubtitle =
        "70% directly supports conservation, keeping you connected to your impact.";

    return (
        <div style={{ position: "relative", width: "100%", height: "100vh" }}>
            <BackgroundVideo />
            <div
                ref={ref}
                className="relative text-white py-8 sm:py-12 lg:py-20 min-h-screen flex items-center justify-center bg-fixed shadow-2xl overflow-hidden"
            >
                <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black opacity-20"></div>
                <div className="sm:w-4/5 container mx-auto px-8 sm:px-8 z-10 relative">
                    <h1
                        className="font-heading text-5xl sm:text-3xl md:text-4xl lg:text-6xl mb-4 text-shadow"
                        data-aos="fade-down"
                        data-aos-duration="800"
                    >
                        {heroTitle}
                    </h1>
                    <p
                        className="text-xl sm:text-sm md:text-lg lg:text-xl mb-8 leading-relaxed text-shadow"
                        style={{ maxWidth: "75%", overflowWrap: "break-word" }}
                        data-aos="fade-up"
                        data-aos-duration="800"
                        data-aos-delay="200"
                    >
                        {heroSubtitle}
                    </p>
                    <iframe
                        src="https://embeds.beehiiv.com/b51d290a-9170-415e-a6d4-0e5c7c54479e?slim=true"
                        data-test-id="beehiiv-embed"
                        height="52"
                        frameBorder="0"
                        scrolling="no"
                        style={{
                            margin: 0,
                            borderRadius: 8,
                            backgroundColor: "transparent",
                            boxShadow: "0 8px 16px 0 rgba(0,0,0,0.07)",
                        }}
                    />
                </div>
            </div>
        </div>
    );
});

HomepageHeroSection.displayName = "HeroSection";

export default HomepageHeroSection;
