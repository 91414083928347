import React from "react";
import clsx from "clsx";

type ButtonProps = {
    children: React.ReactNode;
    onClick?: () => void;
    className?: string;
    disabled?: boolean;
    overrideStyle?: string;
    href?: string;
    target?: string;
    rel?: string;
    title?: string;
};

const Button: React.FC<ButtonProps> = ({
    children,
    onClick,
    className,
    disabled,
    overrideStyle,
    href,
    target,
    rel,
    title,
}) => {
    const disabledStyle = disabled
        ? "bg-gray-300 text-gray-600 cursor-not-allowed"
        : "";

    const baseStyle =
        "text-center font-heading uppercase font-semibold text-base md:text-2xl py-3 md:py-3 px-6 md:px-10 rounded-full bg-accent text-white focus:outline-none shadow-lg text-shadow";

    const hoverAndTransitionStyles = clsx(
        "transition-all duration-300 ease-in-out hover:shadow-xl shadow-lg",
        { "hover:bg-tertiary": !disabled }
    );

    const finalStyle = overrideStyle
        ? `${overrideStyle} ${hoverAndTransitionStyles} ${disabledStyle}`
        : `${baseStyle} ${className} ${hoverAndTransitionStyles}`;

    if (href) {
        return (
            <a
                href={href}
                target={target}
                rel={rel}
                onClick={onClick}
                className={finalStyle}
                title={title}
            >
                {children}
            </a>
        );
    } else {
        return (
            <button
                onClick={onClick}
                className={finalStyle}
                disabled={disabled}
                title={title}
            >
                {children}
            </button>
        );
    }
};

export default Button;
