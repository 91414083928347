import React, { useRef } from "react";
import Header from "../Header";
import HeroSection from "./HeroSection";
// import CollectionBuySection from "./collection_sections/CollectionBuySection";
import CollectionBuyTempSection from "./collection_sections/CollectionBuyTempSection";
import CollectionArjanSection from "./collection_sections/CollectionArjanSection";
import CollectionConvincingSection from "./homepage_sections/HomepageConvincingSection";
import CollectionCTASection from "./collection_sections/CollectionCTASection";
import CollectionQuoteSection from "./collection_sections/CollectionQuoteSection";
import backgroundImage from "../../assets/images/arjan_hill.jpg";
import CollectionBigyearSection from "./collection_sections/CollectionBigyearSection";

const CollectionPage = () => {
    const heroRef = useRef();

    return (
        <div className="gradient-bg min-h-screen collection-page">
            <Header sectionRef={heroRef} />
            <HeroSection
                ref={heroRef}
                title="Arjan's Big Year"
                subtitle="Stunning photography from his world record year"
                backgroundImage={backgroundImage}
            />
            {/* <CollectionBuySection /> */}
            <CollectionBuyTempSection />
            <CollectionArjanSection />
            <CollectionQuoteSection />
            <CollectionBigyearSection />
            <CollectionConvincingSection />
            <CollectionCTASection />
        </div>
    );
};

export default CollectionPage;
