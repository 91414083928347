import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./components/sections/HomePage";
import RestorationPage from "./components/sections/RestorationPage";
import AboutPage from "./components/sections/AboutPage";
import FAQPage from "./components/sections/FAQPage";
import CollectionPage from "./components/sections/CollectionPage";
import NotFound from "./NotFound.js";
import TermsOfUsePage from "./TermsOfUsePage";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import BuyPage from "./components/sections/BuyPage";
import Congratulations from "./components/Congratulations";

const AppRoutes = ({ heroRef }) => {
    return (
        <Routes>
            <Route path="/" element={<HomePage heroRef={heroRef} />} />
            <Route path="/collection" element={<CollectionPage />} />
            <Route path="/restoration" element={<RestorationPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/terms" element={<TermsOfUsePage />} />
            <Route path="/privacy" element={<PrivacyPolicyPage />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/buy" element={<BuyPage />} />
            <Route path="/congratulations" element={<Congratulations />} />
        </Routes>
    );
};

export default AppRoutes;
