import React from "react";
import Button from "components/Button";

import imageArjan from "../../../assets/images/arjan_dwarshuis.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const CollectionArjanSection = () => {
    return (
        <section className="content-container py-20">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-20 items-center">
                    <div
                        className="w-full md:w-4/5 "
                        data-aos="fade-right"
                        data-aos-duration="800"
                    >
                        <img
                            src={imageArjan}
                            alt="Arjan Dwarshuis"
                            className="w-full h-auto object-cover mx-auto rounded-full shadow-2xl"
                        />
                    </div>
                    <div
                        className="text-white sm:-ml-24"
                        data-aos="fade-left"
                        data-aos-duration="800"
                    >
                        <h1 className="text-4xl md:text-5xl font-heading mb-6 text-shadow">
                            Meet Arjan Dwarshuis
                        </h1>
                        <h2 className="text-3xl font-heading mb-6 text-shadow">
                            A multifaceted birdwatcher, conservationist, author,
                            and speaker
                        </h2>
                        <p className="text-sm md:text-base mb-6 text-shadow">
                            From starting his birding journey at just 8 years
                            old, Arjan Dwarshuis has grown into an
                            internationally recognized figure in birdwatching
                            and conservation. With accomplishments such as
                            setting a world record, writing books, producing a
                            documentary, and hosting a birding podcast,
                            Arjan&apos;s passion knows no bounds.
                        </p>
                        <p className="text-sm md:text-base mb-6 text-shadow">
                            His dedication to preserving ecosystems worldwide
                            has made him a prominent figure in global
                            conservation efforts. His latest book, launched in
                            May 2023, has garnered acclaim not just within the
                            birding community, but also among wider audiences.
                        </p>

                        <Button
                            href="https://arjandwarshuis.com/"
                            target="_blank"
                            rel="noreferrer"
                            className="text-xl sm:text-base md:text-lg lg:text-2xl font-heading px-4 sm:px-6 py-2 sm:py-3 transition-all duration-300"
                            data-aos="fade-up"
                            data-aos-duration="800"
                            data-aos-delay="400"
                        >
                            Visit Arjan&apos;s website
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CollectionArjanSection;
