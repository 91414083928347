import React from "react";
import backgroundImage from "./../../../assets/images/restoration_squirrel_unsplash.avif";
import { useMediaQuery } from "react-responsive";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const RestorationQuoteSection = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

    const longQuote =
        "By blending art, tech, and nature, Phuana enables the Land Acquisition Fund to reach a new audience. This can become a remarkable initiative contributing to safeguarding more habitat of endangered species.";
    const shortQuote =
        "Phuana's blend of art, tech, and nature reaches new audiences, potentially revolutionizing habitat preservation for endangered species.";
    const author = "Marc Hoogeslag, Coordinator IUCN NL Land Acquisition Fund";

    const quote = isMobile ? shortQuote : longQuote;
    const [firstPart, secondPart] = quote.split(".", 2);

    return (
        <section className="relative font-heading text-shadow py-20">
            <div
                className="w-full h-72 md:h-80 shadow-xl"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50% 40%",
                    backgroundRepeat: "no-repeat",
                }}
            >
                <div className="container mx-auto px-4 h-full flex items-center">
                    <div className="text-center w-full mr-8 md:mr-12">
                        <p
                            className="text-2xl md:text-4xl text-white italic leading-relaxed -mb-8 sm:mb-4"
                            data-aos="fade-left"
                            data-aos-duration="800"
                        >
                            &ldquo;{firstPart}.
                        </p>
                        <p
                            className="text-xl md:text-4xl text-white italic leading-relaxed mb-4"
                            data-aos="fade-left"
                            data-aos-duration="800"
                        >
                            {secondPart}&rdquo;
                        </p>
                        <p
                            className="text-xl md:text-3xl text-white font-bold mt-8 text-right"
                            data-aos="fade-left"
                            data-aos-duration="800"
                            data-aos-delay="300"
                        >
                            {author}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RestorationQuoteSection;
