import React, { useEffect, useState } from "react";
import MintingCard from "./MintingCard";
import nft_image from "../../../assets/images/birds_collage3-min.png";
import CONTRACT_ABI from "../../../MintNFTsArjanAbi.json";
import { formatEther } from "ethers";
import useReadOnlyContract from "hooks/useReadOnlyContract";
import axios from "axios";

const GenesisMintSection = () => {
    const [priceInMatic, setPriceInMatic] = useState(0);
    const [priceInEth, setPriceInEth] = useState(0);
    const [priceInDollars, setPriceInDollars] = useState(0);
    const [totalNFTs, setTotalNFTs] = useState(0);
    const [isSoldOut, setIsSoldOut] = useState(false);
    const [isMintingActive, setIsMintingActive] = useState(false);

    const contractAddress =
        process.env.REACT_APP_NETWORK === "testnet"
            ? process.env.REACT_APP_CONTRACT_MUMBAI
            : process.env.REACT_APP_CONTRACT_POLYGON;
    const myContract = useReadOnlyContract(CONTRACT_ABI, contractAddress);

    useEffect(() => {
        const fetchPrice = async () => {
            if (myContract) {
                try {
                    const priceWei = await myContract.methods.getPrice().call();
                    const price = formatEther(priceWei.toString());
                    setPriceInMatic(price);
                } catch (error) {
                    console.error("Error reading price: ", error);
                }
            }
        };
        fetchPrice();
    }, [myContract]);

    useEffect(() => {
        const fetchTotalSupply = async () => {
            if (myContract) {
                try {
                    const supply = await myContract.methods
                        .totalSupply()
                        .call();
                    setTotalNFTs(supply.toString());

                    if (supply >= 200) {
                        setIsSoldOut(true);
                    }
                } catch (error) {
                    console.error("Error reading totalSupply: ", error);
                }
            }
        };
        fetchTotalSupply();
    }, [myContract]);

    useEffect(() => {
        const getPriceConversion = async () => {
            let response;

            const cachedData = JSON.parse(
                localStorage.getItem("coingecko_prices")
            );

            // If cache exists and it's not older than a minute, use it
            if (cachedData && Date.now() - cachedData.timestamp < 60000) {
                response = cachedData.data;
            } else {
                // If cache is not available or it's stale, fetch fresh data
                try {
                    const freshData = await axios.get(
                        process.env.REACT_APP_COINGECKO_PRICES_API
                    );
                    response = freshData.data;

                    // Save the fresh data in the cache
                    localStorage.setItem(
                        "coingecko_prices",
                        JSON.stringify({
                            timestamp: Date.now(),
                            data: freshData.data,
                        })
                    );
                } catch (error) {
                    if (cachedData) {
                        // If the fetch fails and there's stale data in the cache, use it
                        response = cachedData.data;
                    } else {
                        console.error(
                            "Error fetching data and no cache available: ",
                            error
                        );
                    }
                }
            }

            try {
                const priceInEthFromAPI = response["matic-network"].eth;
                const priceInUsdFromAPI = response["matic-network"].usd;
                setPriceInEth(parseFloat(priceInMatic) * priceInEthFromAPI);
                setPriceInDollars(parseFloat(priceInMatic) * priceInUsdFromAPI);
            } catch (error) {
                console.error("Error processing price conversion: ", error);
            }
        };
        getPriceConversion();
    }, [priceInMatic]);

    useEffect(() => {
        const fetchMintingStatus = async () => {
            if (myContract) {
                try {
                    const status = await myContract.methods
                        .isMintingActive()
                        .call();
                    setIsMintingActive(status);
                } catch (error) {
                    console.error("Error reading minting status: ", error);
                }
            }
        };
        fetchMintingStatus();
    }, [myContract]);

    const collection = {
        nft: {
            image: nft_image,
            name: "Arjan's Big Year Genesis Collection",
            description:
                "Arjan Dwarshuis' inaugural NFT collection: 200 limited-edition pieces from his record-breaking Big Year. Own a piece of birding history while supporting global nature restoration. Witness the transformation firsthand. 70% of proceeds fund Proyecto Washu.",

            priceInMatic: priceInMatic,
            priceInEth: priceInEth,
            priceInDollars: priceInDollars,
            totalNFTs: 200,
            proceedsContribution: 70,
            proceedsArtist: 15,
            proceedsCosts: 15,
            isMintingActive: isMintingActive,
            isSoldOut: isSoldOut,
        },
        project: {
            name: "Proyecto Washu",
            description:
                "By supporting this project, you contribute to Proyecto Washu's urgent mission of protecting critically endangered species in Ecuador. Help preserve brown-headed spider monkeys and Ecuadorian capuchin monkeys, restore fragmented habitats, and establish a private reserve.",
        },
        progressbar: {
            progress: totalNFTs,
        },
    };

    return (
        <div className="app">
            <MintingCard collection={collection} />
        </div>
    );
};

export default GenesisMintSection;
