import { useState, useEffect } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import { UnsupportedChainIdError } from "@web3-react/core";

export function useWeb3Connection(
    connector: AbstractConnector,
    network: "ethereum" | "polygon"
) {
    const { activate, active, library, deactivate } =
        useWeb3React<Web3Provider>();

    const [connectError, setConnectError] = useState<string | undefined>(
        undefined
    );
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const [account, setAccount] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const [manualDisconnect, setManualDisconnect] = useState(false);

    const customWindow = window as CustomWindow;

    const supportedChainIds =
        network === "ethereum" ? [1, 3, 4, 5, 42] : [137, 80001];

    connector = new InjectedConnector({
        supportedChainIds: supportedChainIds,
    });

    useEffect(() => {
        if (active && library) {
            setIsConnected(true);
            setConnectError(null);
            library
                .getSigner()
                .getAddress()
                .then((address) => {
                    setAccount(address);
                })
                .catch((err) => {
                    console.error("Failed to get account address:", err);
                });
        } else {
            setIsConnected(false);
        }
    }, [active, library]);

    async function connectToProvider() {
        if (manualDisconnect) {
            setManualDisconnect(false);
            return;
        }
        setLoading(true);
        try {
            await activate(connector, undefined, true);
        } catch (err) {
            console.error("Failed to connect wallet:", err);
            if (err instanceof UnsupportedChainIdError) {
                setConnectError("Please connect to the correct network.");
            } else {
                setConnectError("Failed to connect wallet. Please try again.");
            }
        } finally {
            setLoading(false);
        }
    }

    async function disconnectWallet() {
        setLoading(true);
        try {
            deactivate();
            setAccount(null);
            setManualDisconnect(true);
        } catch (err) {
            console.error("Failed to disconnect wallet:", err);
            setConnectError("Failed to disconnect wallet. Please try again.");
        } finally {
            setLoading(false);
        }
    }

    async function handleSwitchWallet(onSwitched: () => void) {
        setLoading(true);
        try {
            deactivate();
            setAccount(null);

            setTimeout(async () => {
                await customWindow.ethereum.request({
                    method: "wallet_requestPermissions",
                    params: [{ eth_accounts: {} }],
                });

                await activate(connector);
                if (onSwitched) {
                    onSwitched();
                }
            }, 1);
        } catch (err) {
            console.error("Failed to switch wallet:", err);
            setConnectError("Failed to switch wallet. Please try again.");
        } finally {
            setLoading(false);
        }
    }

    return {
        isConnected,
        connectError,
        account,
        connectToProvider,
        web3: library,
        disconnectWallet,
        handleSwitchWallet,
        loading,
    };
}

export interface Web3Connection {
    isConnected: boolean;
    loading: boolean;
    account: string | null;
    connectToProvider: () => Promise<void>;
    disconnectWallet: () => Promise<void>;
}
