import React from "react";
import { Link } from "react-router-dom";
import Button from "./../../Button";
import backgroundImage from "./../../../assets/images/parrot.webp";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const HomepageCollectionCTASection = () => {
    return (
        <section className="relative font-heading text-shadow py-20">
            <div
                className="w-full h-72 md:h-80 shadow-xl"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center 50%",
                    backgroundRepeat: "no-repeat",
                }}
            >
                <div className="container mx-auto px-4 h-full flex items-center">
                    <div className="text-right w-full mr-8 md:mr-12">
                        <h2
                            className="text-3xl md:text-5xl text-white font-bold mb-8"
                            data-aos="fade-left"
                            data-aos-duration="800"
                        >
                            Restore nature with us
                        </h2>
                        <Link to="/restoration" className="float-right">
                            <Button
                                data-aos="fade-left"
                                data-aos-duration="800"
                                data-aos-delay="300"
                                className="text-xl"
                            >
                                Learn more
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomepageCollectionCTASection;
