import React, { useEffect, useRef } from "react";
import Header from "../Header";
import HomepageHeroSection from "./homepage_sections/HomepageHeroSection";
import CollectionHowItWorksSection from "./homepage_sections/CollectionHowItWorksSection";
import HomepageCollectionSection from "./homepage_sections/HomepageCollectionSection";
import HomepageRestorationSection from "./homepage_sections/HomepageRestorationSection";
import HomepageCommunitySection from "./homepage_sections/HomepageCommunitySection";
import HomepageConvincingSection from "./homepage_sections/HomepageConvincingSection";
import HomepageOrganizations from "./homepage_sections/HomepageOrganizations";
import HomepageCollectionCTASection from "./homepage_sections/HomepageCollectionCTASection";
import HomepagePhaunaSection from "./homepage_sections/HomePagePhaunaSection";
import HomepageRestorationCTASection from "./homepage_sections/HomepageRestorationCTASection";
import AOS from "aos";
import "aos/dist/aos.css";

const HomePage = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    const heroRef = useRef();

    return (
        <div className="gradient-bg min-h-screen homepage">
            <Header sectionRef={heroRef} />
            <HomepageHeroSection ref={heroRef} />
            <HomepageOrganizations />
            <HomepagePhaunaSection />
            <CollectionHowItWorksSection />
            <HomepageCollectionSection />
            <HomepageCollectionCTASection />
            <HomepageRestorationSection />
            <HomepageRestorationCTASection />
            <HomepageConvincingSection />
            <HomepageCommunitySection />
        </div>
    );
};

export default HomePage;
