import React from "react";
import Button from "components/Button";
import AOS from "aos";
import washu_area from "../../../assets/images/washu_reserve_area2.png";
import "aos/dist/aos.css";

AOS.init();

const RestorationWashuSection = () => {
    return (
        <section className="content-container mt-12 sm:mt-12 sm:py-8 md:py-8 lg:py-8">
            <div className="container mx-auto px-4 ">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
                    <div
                        className="order-2 md:order-1 w-full md:w-2/3 mx-auto"
                        data-aos="fade-right"
                        data-aos-duration="800"
                    >
                        <div className="image-copyright-container">
                            <img
                                src={washu_area}
                                alt="Washu Area"
                                className="w-full h-auto rounded-2xl shadow-2xl"
                            />
                        </div>
                        <div className="py-12 w mt-12 -mb-12 text-center">
                            <Button
                                href="https://www.proyectowashu.org/english"
                                target="_blank"
                                rel="noreferrer"
                                className="font-heading text-xl"
                            >
                                Visit Washu website
                            </Button>
                        </div>
                        {/* <div className="py-12 -mb-12 text-center">
                            <Button
                                href="https://www.iucn.nl/en"
                                target="_blank"
                                rel="noreferrer"
                                className="font-heading text-xl"
                            >
                                Read blog
                            </Button>
                        </div> */}
                    </div>
                    <div
                        className="order-1 md:order-2 text-white"
                        data-aos="fade-left"
                        data-aos-duration="800"
                    >
                        <h2 className="text-4xl md:text-5xl font-heading mb-6 text-shadow">
                            Introducing Proyecto Washu
                        </h2>
                        <p className="text-sm md:text-base mb-6">
                            The proceeds from Arjan&apos;s Big Year collections
                            will support Proyecto Washu, a non-governmental
                            organization dedicated to protecting critically
                            endangered species in Ecuador. This funding is
                            crucial for their conservation efforts and
                            addressing immediate threats to species like
                            brown-headed spider monkeys and Ecuadorian capuchin
                            monkeys.
                        </p>
                        <p className="text-sm md:text-base mb-6">
                            Proyecto Washu implements a Sustainable Matrix
                            Model, conducts scientific research, engages in
                            community-led development, and focuses on ex-situ
                            conservation and environmental education. Their
                            holistic approach combines on-the-ground
                            conservation with community involvement to achieve
                            long-term impact in the region.
                        </p>
                        <p className="text-sm md:text-base mb-6">
                            The action plan includes acquiring several pieces of
                            land from 50-300 hectares to establish a private
                            reserve, creating a sanctuary for endangered
                            primates and contributing to the conservation of the
                            fragmented Ecuadorian coast region. Restoration
                            activities and habitat connectivity are integral to
                            the project, with collaboration among local
                            authorities, communities, and other conservation
                            initiatives to promote biodiversity protection at a
                            larger scale.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RestorationWashuSection;
