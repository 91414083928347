import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import benefits_image1 from "../../../assets/images/benefits/rufous-crested_coquette.png";
import benefits_image2 from "../../../assets/images/butterfly-unsplash.webp";
import benefits_image3 from "../../../assets/images/camera_trap.jpeg";
import benefits_image4 from "../../../assets/images/benefits/benefits_image1.jpg";
import benefits_image5 from "../../../assets/images/black-thighed-grosbeak.webp";

const HomepageConvincingSection = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

    const benefits = [
        {
            image: benefits_image4,
            title: "Exclusive photography",
            text: "Own unique, high-quality photos of rare birds captured during Arjan's record-breaking year",
        },

        {
            image: benefits_image3,
            title: "Real-time restoration updates",
            text: "Stay informed with exclusive updates and behind-the-scenes footage of ongoing restoration efforts",
        },
        {
            image: benefits_image1,
            title: "Own a scarce collectible",
            text: "Join an elite group of collectors by owning one of only 200 Genesis collection NFTs",
        },
        {
            image: benefits_image2,
            title: "70% to support nature",
            text: "Feel good knowing 70% of your purchase directly funds trusted and impactful nature restoration",
        },
        {
            image: benefits_image5,
            title: "Future access & perks",
            text: "Unlock priority access to Phauna's upcoming digital collections and exclusive benefits",
        },
    ];

    return (
        <section className="content-section py-8 sm:py-20 convincing-section">
            <h2 className="text-white text-5xl md:text-6xl font-heading sm:mb-16 text-center text-shadow ">
                Why buy the Genesis collection?
            </h2>
            <div className="container mx-auto px-8 md:px-16 mt-8 no-overflow">
                <div className="relative">
                    {benefits.map((benefit, index) => (
                        <div
                            key={index}
                            data-aos={
                                index % 2 === 0 ? "fade-right" : "fade-left"
                            }
                            className={`benefit-card rounded-3xl shadow-2xl p-6 md:p-12 flex flex-col md:flex-row items-center ${
                                index % 2 === 0 ? "mr-auto" : "ml-auto "
                            } relative`}
                            style={{
                                width: "calc(50% - 4rem)",
                                marginTop: index === 0 ? "0" : "-7rem",
                                marginBottom:
                                    index === benefits.length - 1
                                        ? "0"
                                        : "2rem",
                                background:
                                    "linear-gradient(to right, #3a9d9b, #3A939B)",
                                justifyContent: "center",
                                position: "relative",
                            }}
                        >
                            <div
                                className={`flex items-center  ${
                                    index % 2 === 0
                                        ? "flex-row-reverse"
                                        : "flex-row"
                                }`}
                            >
                                <img
                                    src={benefit.image}
                                    alt={benefit.title}
                                    className={`w-24 h-24 md:w-32 md:h-32 rounded-full object-cover shadow-lg absolute ${
                                        index % 2 === 0 ? "ml-12" : "mr-12"
                                    }`}
                                    style={{
                                        top: "50%", // Center it vertically
                                        left: index % 2 === 0 ? "100%" : "auto", // Move the image to the right for even-indexed items
                                        right:
                                            index % 2 === 0 ? "auto" : "100%", // Move the image to the left for odd-indexed items
                                        transform:
                                            index % 2 === 0
                                                ? "translateY(-50%) translateX(-90%)"
                                                : "translateY(-50%) translateX(90%)",
                                    }}
                                />
                                <div
                                    className={`${
                                        index % 2 === 0
                                            ? "text-left mr-8"
                                            : "text-right ml-8"
                                    }`}
                                >
                                    <h3 className="text-white text-2xl md:text-3xl font-heading text-shadow font-bold">
                                        {benefit.title}
                                    </h3>
                                    <p className="text-white text- md:text-base font-body text-shadow pt-4">
                                        {benefit.text}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default HomepageConvincingSection;
