import React from "react";
import Header from "./components/Header";

const TermsOfUsePage = () => {
    return (
        <div className="min-h-screen flex flex-col">
            <Header />
            <div className="flex-grow flex items-center justify-center p-6 sm:p-10 text-left">
                <div className="w-4/5 terms-of-use-page py-20 sm:px-32">
                    <h1 className="font-heading text-3xl">
                        Terms of Use (&#34;Terms&#34;)
                    </h1>
                    <p>Last updated: May 18, 2023</p>

                    <h2>1. Acceptance of Terms</h2>
                    <p>
                        By accessing and using the Services of Phauna B.V.
                        (&#34;the Company&#34;), you acknowledge that you have
                        read, understood, and agree to be bound by these Terms.
                        If you do not accept these Terms, you are not authorized
                        to use the Services. The Company may modify these Terms
                        at any time, and such modification will be effective
                        immediately upon posting to the website.
                    </p>

                    <h2>2. Your Use of the Services</h2>
                    <p>
                        You agree to use the Services only for purposes that are
                        legal, proper, and in accordance with these Terms and
                        any applicable laws and regulations.
                    </p>

                    <h2>3. User Content</h2>
                    <p>
                        You retain ownership of all content you submit to the
                        Services (&#34;User Content&#34;). However, by
                        submitting User Content, you grant the Company a
                        worldwide, royalty-free, non-exclusive, transferable,
                        and sublicensable license to use, copy, modify,
                        distribute, display, and perform your User Content.
                    </p>

                    <h2>4. Prohibited Conduct</h2>
                    <p>
                        You agree not to use the Services to post, upload, or
                        distribute any User Content that is against the law,
                        harmful, threatening, or otherwise objectionable.
                    </p>

                    <h2>5. Termination</h2>
                    <p>
                        The Company may terminate your access to all or any part
                        of the Services at any time, with or without cause,
                        effective immediately.
                    </p>

                    <h2>6. Disclaimer of Warranties</h2>
                    <p>
                        The Services are provided &#34;as is&#34; and &#34;as
                        available&#34;. The Company disclaims all warranties of
                        any kind, express or implied, including, but not limited
                        to, the warranties of merchantability, fitness for a
                        particular purpose, and non-infringement.
                    </p>

                    <h2>7. Limitation of Liability</h2>
                    <p>
                        In no event will the Company be liable for any damages,
                        including, but not limited to, incidental, special,
                        consequential, or punitive damages, arising out of or in
                        connection with your use of the Services.
                    </p>

                    <h2>8. Indemnity</h2>
                    <p>
                        You agree to indemnify and hold the Company, its
                        officers, directors, shareholders, predecessors,
                        successors in interest, employees, agents, subsidiaries,
                        and affiliates harmless from any demands, loss,
                        liability, claims, or expenses, including
                        attorneys&apos; fees, made against the Company by any
                        third party due to or arising out of or in connection
                        with your use of the Services.
                    </p>

                    <h2>9. Copyright Policy</h2>
                    <p>
                        The Company respects the intellectual property rights of
                        others and expects its users to do the same. In
                        accordance with the Digital Millennium Copyright Act of
                        1998, the Company will respond expeditiously to claims
                        of copyright infringement committed.
                    </p>

                    <h2>10. Privacy</h2>
                    <p>
                        Your use of the Services is subject to the
                        Company&apos;s Privacy Policy, which is available at
                        [link to privacy policy].
                    </p>

                    <h2>11. Links to Other Sites</h2>
                    <p>
                        The Services may contain links to third-party websites
                        or resources. You acknowledge and agree that the Company
                        is not responsible or liable for the availability or
                        accuracy of such websites or resources, or the content,
                        products, or services on or available from those other
                        websites or resources.
                    </p>

                    <h2>12. Dispute Resolution and Governing Law</h2>
                    <p>
                        These Terms and any action related thereto will be
                        governed by the laws of the Netherlands without regard
                        to its conflict of laws provisions. All claims, legal
                        proceedings, or litigation arising in connection with
                        the Services will be brought solely in the Netherlands,
                        and you consent to the jurisdiction of and venue in such
                        courts.
                    </p>

                    <h2>13. Contact Us</h2>
                    <p>
                        If you have any questions about these Terms, please
                        contact us at info@phauna.earth.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TermsOfUsePage;
