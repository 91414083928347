import React from "react";
import { NavLink } from "react-router-dom";

const Navbar = ({
    mobile = false,
    dropdownOpen,
    showDropdown,
    hideDropdown,
    setDropdownOpen,
}) => {
    const navClass = mobile
        ? "block px-2 py-2 text-2xl text-left"
        : "px-3 py-2 rounded-lg hover:bg-accent-500 hover:text-accent transition-colors duration-200 font-bold text-2xl";

    return (
        <nav>
            <ul
                className={`flex ${
                    mobile ? "flex-col" : "flex-row"
                }  items-center px-2 font-heading flex-wrap`}
            >
                <li>
                    <NavLink
                        to="/"
                        className={navClass}
                        activeclassname="bg-accent text-primary"
                    >
                        Home
                    </NavLink>
                </li>

                <li>
                    <NavLink
                        to="/buy"
                        className={navClass}
                        activeclassname="bg-accent text-primary"
                    >
                        Buy
                    </NavLink>
                </li>

                {mobile ? (
                    <li>
                        <NavLink
                            to="/collection"
                            className={navClass}
                            activeclassname="bg-accent text-primary"
                        >
                            Arjan&apos;s Big Year
                        </NavLink>
                    </li>
                ) : (
                    <li className="relative">
                        <span
                            onMouseEnter={showDropdown}
                            onMouseLeave={hideDropdown}
                            className="cursor-default px-3 py-2 text-2xl rounded-lg hover:bg-accent-500 hover:text-accent transition-colors duration-200"
                        >
                            Collections
                        </span>
                        {dropdownOpen && (
                            <div
                                onMouseEnter={showDropdown}
                                onMouseLeave={hideDropdown}
                                className="absolute left-0 mt-2 bg-primary shadow-lg rounded-lg py-2 z-10 w-auto whitespace-nowrap"
                            >
                                <NavLink
                                    to="/collection"
                                    className="inline-block px-4 py-2 text-2xl font-semibold hover:text-accent transition-colors duration-200"
                                    activeclassname="text-accent"
                                    onClick={() => setDropdownOpen(false)}
                                >
                                    Arjan&apos;s Big Year
                                </NavLink>
                            </div>
                        )}
                    </li>
                )}
                <li>
                    <NavLink
                        to="/restoration"
                        className={navClass}
                        activeclassname="bg-accent text-primary"
                    >
                        Nature Restoration
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/about"
                        className={navClass}
                        activeclassname="bg-accent text-primary"
                    >
                        About
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/faq"
                        className={navClass}
                        activeclassname="bg-accent text-primary"
                    >
                        FAQ
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
