import React from "react";

interface ErrorNotificationProps {
    message: string;
}

const ErrorNotification: React.FC<ErrorNotificationProps> = ({ message }) => {
    return (
        <div className="bg-red-600 text-white px-4 py-2 rounded-md mb-4">
            <p>{message}</p>
        </div>
    );
};

export default ErrorNotification;
