import React from "react";
import afterExample from "../../../assets/images/BOL_ARM-BA_SPN_1-compressed.webp";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
    offset: 80,
});

const HomepageRestorationSection = () => {
    const promises = [
        {
            title: "70% of sales & royalties",
            description:
                "Directly funding nature restoration projects through smart contracts",
        },
        {
            title: "Global NGO collaboration",
            description:
                "Partnering with a highly trusted organization for project execution",
        },
        {
            title: "Transparency & communication",
            description: "Open and regular updates on progress and results",
        },
        {
            title: "Community involvement",
            description:
                "Engaging local communities for sustainable initiatives",
        },
        {
            title: "Financial accountability",
            description:
                "Harnessing blockchain for unwavering financial transparency",
        },
    ];

    return (
        <section className="custom-bg py-20 content-container restoration-section">
            <div className="container mx-auto px-4 text-white">
                <h2
                    className="font-heading text-6xl mb-16 text-center text-shadow"
                    data-aos="fade-down"
                    data-aos-duration="800"
                >
                    Our 5 promises on nature restoration
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-start">
                    <div className="grid grid-cols-1 gap-6 px-8 sm:px-0">
                        {promises.map((promise, index) => (
                            <div key={index} className="flex items-start ">
                                <h4
                                    className="font-heading text-shadow text-3xl mb-1 mr-4"
                                    data-aos="fade-right"
                                    data-aos-duration="800"
                                    data-aos-delay={200 * index}
                                >
                                    {index + 1}.
                                </h4>
                                <div>
                                    <h4
                                        className="font-heading text-shadow text-3xl mb-1"
                                        data-aos="fade-right"
                                        data-aos-duration="800"
                                        data-aos-delay={200 * index}
                                    >
                                        {promise.title}
                                    </h4>
                                    <p
                                        className="text-xl text-shadow"
                                        data-aos="fade-right"
                                        data-aos-duration="800"
                                        data-aos-delay={200 * index + 100}
                                    >
                                        {promise.description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="image-copyright-container flex flex-col items-center justify-center px-8 sm:px-0 mt-4 sm:mt-12">
                        <img
                            src={afterExample}
                            alt="Nature restored"
                            className="rounded-full shadow-2xl"
                            data-aos="fade-left"
                            data-aos-duration="800"
                        />
                        <div className="image-copyright-overlay">
                            © Christiaan Spencer
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default HomepageRestorationSection;
