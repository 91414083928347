import React, { useState, useEffect, useRef } from "react";
import Button from "../../../Button";

const BuySectionNumberNFTs = ({ nftCount, setNftCount }) => {
    const [inputValue, setInputValue] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const inputRef = useRef(null);
    const errorTimeoutRef = useRef(null);

    const selectNftCount = (count) => {
        setInputValue("");
        setErrorMessage("");
        setNftCount(count);
    };

    const handleInputChange = (e) => {
        setErrorMessage("");
        const value = Number(e.target.value);
        if (!Number.isInteger(value) || value < 0 || value > 11) {
            setErrorMessage("Please enter a value between 1 and 11");
            if (errorTimeoutRef.current) clearTimeout(errorTimeoutRef.current);
            errorTimeoutRef.current = setTimeout(() => {
                setErrorMessage("");
            }, 2500);
            return;
        } else {
            setErrorMessage("");
        }
        setInputValue(e.target.value);
        setNftCount(value);
    };

    useEffect(() => {
        setNftCount(1);
        return () => {
            if (errorTimeoutRef.current) clearTimeout(errorTimeoutRef.current);
        };
    }, []);

    return (
        <div>
            <label className="block mb-2 text-m">Number of birds to buy:</label>
            <div className="flex justify-between space-x-2">
                {[1, 2, 3, 5].map((num) => (
                    <div key={num} className="flex-grow">
                        <Button
                            onClick={() => selectNftCount(num)}
                            overrideStyle={`w-full text-center font-heading text-lg py-2 rounded-full ${
                                nftCount === num && !inputValue
                                    ? "bg-accent text-white"
                                    : "bg-secondary"
                            }`}
                        >
                            {num}
                        </Button>
                    </div>
                ))}
                <div className="flex-grow">
                    <input
                        ref={inputRef}
                        className={`w-full text-center font-heading text-lg py-2 rounded-full ${
                            inputValue ? "bg-accent text-white" : "bg-secondary"
                        }`}
                        type="number"
                        min="1"
                        max="20"
                        value={inputValue}
                        placeholder={!inputValue ? ". . ." : ""}
                        onFocus={() => {
                            setInputValue("");
                            setNftCount(0);
                        }}
                        onChange={handleInputChange}
                        onClick={() => {
                            if (!inputValue) {
                                inputRef.current.focus();
                            }
                        }}
                    />
                </div>
            </div>
            {errorMessage && (
                <div className="mt-2">
                    <p className="text-orange-300 ">{errorMessage}</p>
                </div>
            )}
        </div>
    );
};

export default BuySectionNumberNFTs;
