import React from "react";
import big_year_photo from "../../../assets/images/arjan_big_year.webp";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const CollectionBigyearSection = () => {
    return (
        <section className="content-container py-8">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-5 gap-20 items-center">
                    <div
                        className="text-white md:col-span-3"
                        data-aos="fade-right"
                        data-aos-duration="800"
                    >
                        <h2 className="text-4xl md:text-5xl font-heading mb-6 text-shadow">
                            Arjan&apos;s Big Year
                        </h2>
                        <p className="text-sm md:text-base mb-6 text-shadow">
                            &quot;In 2016, I embarked on a journey that would
                            forever change my life - my &apos;Big Year&apos;.
                            The goal? To set a new world record by observing as
                            many bird species as possible within a single
                            calendar year. This endeavor took me across 40
                            countries and 6 continents, introducing me to a
                            dazzling array of over 6,852 bird species.
                        </p>
                        <p className="text-sm md:text-base mb-6 text-shadow">
                            Each day was an adventure, filled with the
                            unexpected. I found myself captivated not only by
                            the beauty and diversity of the birds I encountered
                            but also by the power of human connection. I was
                            constantly moved by the birding community&apos;s
                            shared passion and commitment to preserving these
                            natural wonders.
                        </p>
                        <p className="text-sm md:text-base mb-6 text-shadow">
                            What began as a personal challenge evolved into a
                            deepened appreciation for our planet and a resolve
                            to fight for its protection. Each photograph in my
                            NFT collection is a tribute to this incredible
                            journey - a testament to the beauty of our natural
                            world and an urgent call to action.
                        </p>
                        <p className="text-sm md:text-base mb-6 text-shadow">
                            By participating in this unique project, you&apos;re
                            not only acquiring a piece of this monumental
                            journey, but you&apos;re also directly contributing
                            to the protection of the very wonders that inspired
                            it. Together, let&apos;s celebrate and safeguard our
                            planet&apos;s breathtaking biodiversity.&quot;
                        </p>
                    </div>
                    <div
                        className="w-full md:w-full mx-auto md:col-span-2 -mt-4 sm:mt-0"
                        data-aos="fade-left"
                        data-aos-duration="800"
                    >
                        <img
                            src={big_year_photo}
                            alt="Big Year Photo"
                            className="w-full h-auto rounded-full shadow-2xl"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CollectionBigyearSection;
