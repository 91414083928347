import React, { useState } from "react";
import { Transition } from "react-transition-group";

// Importing the local JSON data.
import faqs from "./FAQ.json";

const FAQItem = ({
    question,
    answer,
    link,
    postAnswer,
    isOpen,
    setIsOpen,
    index,
}) => {
    const duration = 300;

    const defaultStyle = {
        transition: `max-height ${duration}ms ease-in-out`,
        maxHeight: 0,
        overflow: "hidden",
    };

    const transitionStyles = {
        entering: { maxHeight: "1000px" },
        entered: { maxHeight: "1000px" },
        exiting: { maxHeight: 0 },
        exited: { maxHeight: 0 },
    };

    return (
        <div className="border-b border-gray-200">
            <button
                className={`w-full p-4 text-left focus:outline-none ${
                    isOpen ? "text-white" : "text-gray-300"
                }`}
                onClick={() => {
                    isOpen ? setIsOpen(-1) : setIsOpen(index);
                }}
            >
                <div className="flex justify-between items-center">
                    <span className="font-bold">{question}</span>{" "}
                    <span>{isOpen ? "-" : "+"}</span>
                </div>
            </button>
            <Transition in={isOpen} timeout={duration}>
                {(state) => (
                    <div
                        style={{
                            ...defaultStyle,
                            ...transitionStyles[state],
                        }}
                    >
                        <div className="p-4 text-white font-body text-lg">
                            {answer}
                            {link && (
                                <a
                                    href={link.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="underline"
                                >
                                    {link.text}
                                </a>
                            )}
                            {postAnswer}
                        </div>{" "}
                    </div>
                )}
            </Transition>
        </div>
    );
};

const FAQContentSection = () => {
    const [openedCategoryIndex, setOpenedCategoryIndex] = useState(-1);
    const [openedItemIndex, setOpenedItemIndex] = useState(-1);

    return (
        <div className="content-container mx-auto mt-16">
            <div className=" text-3xl w-full font-heading max-w-xl px-4 sm:px-6 md:max-w-2xl mx-auto tex-shadow">
                {faqs.map((category, catIndex) => (
                    <>
                        <div className="mt-4">
                            <button
                                className={`w-full p-4 text-left focus:outline-none ${
                                    openedCategoryIndex === catIndex
                                        ? "text-white"
                                        : "text-gray-200"
                                }`}
                                onClick={() => {
                                    openedCategoryIndex === catIndex
                                        ? setOpenedCategoryIndex(-1)
                                        : setOpenedCategoryIndex(catIndex);
                                    setOpenedItemIndex(-1);
                                }}
                            >
                                <div className="flex justify-between items-center">
                                    <span className="font-bold">
                                        {category.category}
                                    </span>{" "}
                                    <span>
                                        {openedCategoryIndex === catIndex
                                            ? "-"
                                            : "+"}
                                    </span>
                                </div>
                            </button>
                        </div>
                        {category.faqs.map(
                            (faq, index) =>
                                openedCategoryIndex === catIndex && (
                                    <FAQItem
                                        key={index}
                                        question={faq.question}
                                        answer={faq.answer}
                                        link={faq.link}
                                        postAnswer={faq.postAnswer}
                                        isOpen={openedItemIndex === index}
                                        setIsOpen={setOpenedItemIndex}
                                        index={index}
                                    />
                                )
                        )}
                    </>
                ))}
            </div>
        </div>
    );
};

export default FAQContentSection;
