import React from "react";
import { Link } from "react-router-dom";
import Button from "./../../Button";
import backgroundImage from "./../../../assets/images/arjan_cta4.webp";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const HomepageCollectionCTASection = () => {
    return (
        <section className="relative mb-20 sm:mb-0 sm:py-20">
            <div
                className="w-full h-72 md:h-80 bg-container-collection-cta shadow-2xl"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                }}
            >
                <div className="container mx-auto px-4 h-full flex items-center ">
                    <div className="text-right font-heading w-full mr-8 md:mr-12 text-shadow">
                        <h2
                            className="text-3xl md:text-5xl text-white font-bold mb-8"
                            data-aos="fade-left"
                            data-aos-duration="800"
                        >
                            Meet the artist: Arjan Dwarshuis
                        </h2>
                        <Link to="/collection" className="float-right ">
                            <Button
                                data-aos="fade-left"
                                data-aos-duration="800"
                                data-aos-delay="300"
                                className="text-xl"
                            >
                                Explore the collection
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomepageCollectionCTASection;
