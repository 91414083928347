import React, { useState } from "react";
import Slider from "react-slick";
import image2 from "../../../assets/images/IMG_3099-sharpen-motion.webp";
import image3 from "../../../assets/images/IMG_3183-sharpen-focus.webp";
import image4 from "../../../assets/images/IMG_3585-sharpen-focus.webp";
import image5 from "../../../assets/images/IMG_4615-sharpen-focus.webp";
import image6 from "../../../assets/images/IMG_4978-sharpen-focus.webp";
import "./HomepageCollectionSection.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const images = [
    {
        src: image2,
        object_position: "100% 60%",
        object_position_mobile: "100% 60%",
        thumbnail: image2,
        species: "Black-crowned Pittasoma",
        from_arjan:
            "This highly sought-after antpitta is usually found near army ant swarms. It was one of my most wanted birds in the Neotropics, and I finally saw it at an ant swarm on my last day in Panama.",
        status: "Least concern",
        population: "20,000-49,999",
        rarity: "Scarce",
        range: "Northwestern Colombia to Costa Rica",
        habitat: "Lowland and hill forest",
        arjans_ranking: "27",
    },
    {
        src: image3,
        object_position: "100% 60%",
        object_position_mobile: "100% 60%",
        thumbnail: image3,
        species: "Resplendent Quetzal",
        from_arjan:
            "This trogon is often recognized as the most beautiful bird in the world. It was of great importance to ancient Mesoamerican cultures, being present in various legends and myths. It was considered divine and associated with Quetzalcoatl, the god of life. I observed this bird in the highlands of Costa Rica on the same day I broke the world record.",
        status: "Near threatened",
        population: "Unknown",
        rarity: "Uncommon",
        range: "Highlands of Panama to Southwest-Mexico",
        habitat: "Cloud Forest",
        arjans_ranking: "22",
    },
    {
        src: image4,
        object_position: "100% 10%",
        object_position_mobile: "100% 60%",
        thumbnail: image4,
        species: "Horned Guan",
        from_arjan:
            "To see this bird, I had to hike more than a kilometer up the slope of the Atitlan Volcano in Guatemala in the dark so that I would arrive at the site at first light. I only saw it in the dying seconds.",
        status: "Endangered",
        population: "600-1,700",
        rarity: "Rare",
        range: "Highlands of Southwest-Mexico and Northwest Guatemala",
        habitat: "Cloud forest",
        arjans_ranking: "3",
    },
    {
        src: image5,
        object_position: "100% 60%",
        object_position_mobile: "100% 60%",
        thumbnail: image5,
        species: "Evening Grossbeak",
        from_arjan:
            "The Evening Grosbeak is one of the most stunning finch species in the world. It is widespread but also considered vulnerable, as it has declined dramatically over the course of the last few decades. I found this bird very easily at a feeder in Duluth, Minnesota, making it the most northern bird of my entire trip.",
        status: "Vulnerable",
        population: "3,400,000",
        rarity: "Rare",
        range: "Highlands of Southwest-Mexico and Northwest Guatemala",
        habitat: "Cloud forest",
        arjans_ranking: "42",
    },
    {
        src: image6,
        object_position: "100% 0%",
        object_position_mobile: "100% 60%",
        thumbnail: image6,
        species: "Cryptic Forest Falcon",
        from_arjan:
            "This secretive forest-dwelling raptor was discovered as recently as 2002 by the famous ornithologist Andrew Whittaker. It was originally classified as a Lined Forest Falcon, but after Andrew heard its distinctive song, he realized it was a totally different species. I observed this stunning raptor multiple times around the Rio Azul Jungle Lodge, where we observed 270 species in just 4 days.",
        status: "Least concern",
        population: "Unknown",
        rarity: "Scarce",
        range: "Brazil and eastern Bolivia",
        habitat: "Lowland Terra Firma rain forest",
        arjans_ranking: "23",
    },
];

const HomepageCollectionSection = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [showComment, setShowComment] = useState(false);
    const [comment, setComment] = useState("");

    const handleBeforeChange = (oldIndex, newIndex) => {
        setActiveIndex(newIndex);
    };

    const isMobile = window.innerWidth <= 768;

    const settings = {
        dots: true,
        infinite: true,
        speed: isMobile ? 500 : 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: isMobile ? 3000 : 5000,
        pauseOnHover: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        centerMode: true,
        centerPadding: "0%",
        beforeChange: handleBeforeChange,
    };

    return (
        <section className="content-section py-12 sm:py-20 overflow-hidden-slider">
            <div>
                <h2 className="font-heading text-5xl md:text-6xl mb-12 px-8 text-center text-white text-shadow">
                    Arjan&apos;s Big Year Genesis Collection
                </h2>
                <div className="container mx-auto px-4 md:px-16 mt-8">
                    <div className="homepage-collection-section-slider-container">
                        <div
                            className={`slider-comment-box ${
                                showComment ? "visible" : ""
                            }`}
                        >
                            <p>{comment}</p>
                        </div>
                        <Slider {...settings}>
                            {images.map((image, index) => (
                                <div
                                    key={index}
                                    className={`slider-item ${
                                        index === activeIndex
                                            ? "active-slide"
                                            : ""
                                    } flex flex-col`}
                                >
                                    <img
                                        src={image.src}
                                        alt={image.species}
                                        className={`homepage-collection-section-slider-image ${
                                            index === activeIndex
                                                ? `image-${index}`
                                                : ""
                                        }`}
                                    />
                                    <div
                                        className="slider-item-caption overflow-hidden"
                                        onMouseEnter={() => {
                                            setComment(
                                                <>
                                                    <h3
                                                        className="font-heading text-shadow"
                                                        style={{
                                                            fontSize: "24px",
                                                            color: "white",
                                                            marginBottom:
                                                                "10px",
                                                        }}
                                                    >
                                                        Arjan&apos;s Story:
                                                    </h3>
                                                    <p>{image.from_arjan}</p>
                                                </>
                                            );
                                            setShowComment(true);
                                        }}
                                        onMouseLeave={() =>
                                            setShowComment(false)
                                        }
                                    >
                                        <div className="small-text">
                                            <strong className="font-heading text-xl text-shadow">
                                                Species:
                                            </strong>{" "}
                                            <br />
                                            {image.species}
                                        </div>

                                        <div className="small-text">
                                            <strong className="font-heading text-xl text-shadow">
                                                Rarity:
                                            </strong>
                                            <br />
                                            {image.rarity}
                                        </div>

                                        <div className="small-text">
                                            <strong className="font-heading text-xl text-shadow">
                                                Population:
                                            </strong>
                                            <br />
                                            {image.population}
                                        </div>

                                        <div className="small-text">
                                            <strong className="font-heading text-xl text-shadow">
                                                Status:
                                            </strong>
                                            <br />
                                            {image.status}
                                        </div>

                                        <div className="small-text">
                                            <strong className="font-heading text-xl text-shadow">
                                                Range:
                                            </strong>
                                            <br />
                                            {image.range}
                                        </div>
                                        <div className="small-text">
                                            <strong className="font-heading text-xl text-shadow">
                                                Habitat:
                                            </strong>
                                            <br />
                                            {image.habitat}
                                        </div>
                                        <div className="small-text">
                                            <strong className="font-heading text-xl text-shadow">
                                                Arjan&apos;s Ranking:
                                            </strong>
                                            <br />
                                            {image.arjans_ranking}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
};

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <button
            className="slick-next slick-arrow custom-arrow"
            onClick={onClick}
        ></button>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <button
            className="slick-prev slick-arrow custom-arrow"
            onClick={onClick}
        ></button>
    );
}

export default HomepageCollectionSection;
