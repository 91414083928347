import React from "react";
import Header from "./components/Header";

const PrivacyPolicyPage = () => {
    return (
        <div className="min-h-screen flex flex-col">
            <Header />
            <div className="flex-grow flex items-center justify-center p-6 sm:p-10 text-left">
                <div className="w-4/5 privacy-policy-page py-20 sm:px-32">
                    <h1 className="font-heading text-3xl">
                        Privacy Policy of Phauna B.V.
                    </h1>
                    <p>Last updated: May 18, 2023</p>

                    <h2>1. Introduction</h2>
                    <p>
                        Phauna B.V. (&#34;we&#34;, &#34;our&#34;, or
                        &#34;us&#34;) respects your privacy and is committed to
                        protecting it through our compliance with this policy.
                        This policy describes the types of information we may
                        collect from you or that you may provide when you use
                        our services, and our practices for collecting, using,
                        maintaining, protecting, and disclosing that
                        information.
                    </p>

                    <h2>2. Information We Collect About You</h2>
                    <p>
                        We collect several types of information from and about
                        users of our services, including information: - By which
                        you may be personally identified, such as name, postal
                        address, e-mail address, telephone number (&#34;personal
                        information&#34;). - About your internet connection, the
                        equipment you use to access our services, and usage
                        details.
                    </p>

                    <h2>3. How We Use Your Information</h2>
                    <p>
                        We use information that we collect about you or that you
                        provide to us, including any personal information: - To
                        present our services to you. - To provide you with
                        information, products, or services that you request from
                        us. - To fulfill any other purpose for which you provide
                        it. - To carry out our obligations and enforce our
                        rights arising from any contracts entered into between
                        you and us, including for billing and collection.
                    </p>

                    <h2>4. Disclosure of Your Information</h2>
                    <p>
                        We may disclose aggregated information about our users,
                        and information that does not identify any individual,
                        without restriction. We may disclose personal
                        information that we collect or you provide as described
                        in this privacy policy: - To our subsidiaries and
                        affiliates. - To contractors, service providers, and
                        other third parties we use to support our business. - To
                        fulfill the purpose for which you provide it. - For any
                        other purpose disclosed by us when you provide the
                        information.
                    </p>

                    <h2>5. Data Security</h2>
                    <p>
                        We have implemented measures designed to secure your
                        personal information from accidental loss and from
                        unauthorized access, use, alteration, and disclosure.
                    </p>

                    <h2>6. Changes to Our Privacy Policy</h2>
                    <p>
                        It is our policy to post any changes we make to our
                        privacy policy on this page. If we make material changes
                        to how we treat our users&apos; personal information, we
                        will notify you. The date the privacy policy was last
                        revised is identified at the top of the page.
                    </p>

                    <h2>7. Contact Information</h2>
                    <p>
                        To ask questions or comment about this privacy policy
                        and our privacy practices, contact us at:
                        info@phauna.earth.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicyPage;
