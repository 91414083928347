import React from "react";
import image1 from "../../../assets/images/monkey.webp";
import image2 from "../../../assets/images/david-clode-madagascar.jpg";
import image3 from "../../../assets/images/IMG_3585-sharpen-focus.webp";
import image4 from "../../../assets/images/corridor.png";
import image5 from "../../../assets/images/sophron.png";
import image6 from "../../../assets/images/IMG_3183-sharpen-focus.webp";
import image7 from "../../../assets/images/forest.jpeg";
import { FaTwitter, FaLinkedin } from "react-icons/fa";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const AboutPage = () => {
    const sections = [
        {
            src: image1,
            backgroundPosition: "100% 45%",
            title: "Introduction",
            text: "Welcome to Phauna, a unique intersection of breathtaking digital art and tangible support for biodiversity. Endorsed by a globally renowned NGO, our digital art platform takes pride in its transparency, assuring that your contributions to nature restoration are not just rewarding but also create meaningful, lasting impacts.",
        },
        {
            src: image5,
            backgroundPosition: "100% 50%",
            title: (
                <div className="flex items-center">
                    <span>Meet the founder </span>
                    <a
                        href="https://twitter.com/sophron_phauna"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-tertiary transition-colors duration-200 text-shadow"
                    >
                        <FaTwitter className="ml-5" size={30} />
                    </a>
                    <a
                        href="https://linkedin.com/in/svermeij"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-tertiary transition-colors duration-200 text-shadow"
                    >
                        <FaLinkedin className="ml-5" size={30} />
                    </a>
                </div>
            ),
            text: "Sophron founded a Dutch AI consultancy five years ago, but it was the advent of 'Regenerative Finance' that truly sparked his passion. Combining his technological expertise with a commitment to environmental sustainability, he regards Phauna as more than just a project; it's the embodiment of his personal mission to drive long-term ecological change.",
        },
        {
            src: image2,
            backgroundPosition: "100% 45%",
            title: "Our story",
            text: "Phauna was born out of a belief that supporting the environment should be engaging, personal, and transparent. We transform donations into an experience, connecting art lovers to nature preservation. With Phauna, you acquire inspiring art, join a passionate community, and witness your tangible impact on the environment.",
        },
        {
            src: image3,
            backgroundPosition: "100% 5%",
            title: "Our collections",
            text: "Arjan Dwarshuis, a globally acclaimed birder, presents our first collection. Through his lens, he captures his record-breaking birding year, featuring stunning images of the world's most endangered species. His passion for birding is palpable and infectious.",
        },
        {
            src: image7,
            backgroundPosition: "100% 45%",
            title: "Roadmap",
            text: `Phauna's journey first big milestone is August 1st with our Genesis collection of 200 pieces. The 'Arjan's Big Year' main collection launches in November, followed by a second main collection in the Q1 2024. This is just the start, envisioning Phauna as a scalable platform for sustainable digital art in the next 3 years.`,
        },
        {
            src: image4,
            backgroundPosition: "10% 10%",
            title: "Our Impact",
            text: "70% of our proceeds are dedicated to land acquistion for nature restoration, combating climate change and promoting biodiversity. With trusted global organizations, we provide regular updates on the progress, allowing art holders to witness the impact of their contributions.",
        },

        {
            src: image6,
            backgroundPosition: "100% 15%",
            title: "Join the community",
            text: (
                <span>
                    Join our community, where we share restoration progress and
                    the beauty of nature. Follow us on{" "}
                    <a
                        href="https://twitter.com/phauna_earth"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            textDecoration: "underline",
                        }}
                    >
                        Twitter
                    </a>{" "}
                    and join us on{" "}
                    <a
                        href={process.env.REACT_APP_DISCORD_INVITE}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            textDecoration: "underline",
                        }}
                    >
                        Discord
                    </a>
                    . Be part of our journey to protect and restore Earth&apos;s
                    beauty, creating a greener future for generations to come.
                </span>
            ),
        },
    ];

    return (
        <div>
            <section className="content-container mt-20 py-20 mb-20">
                <div className="container mx-auto px-4 sm:px-0">
                    <div className="grid grid-cols-1 gap-20">
                        {sections.map((section, index) => (
                            <div
                                key={index}
                                className={`flex ${
                                    index % 2 === 0
                                        ? "flex-col"
                                        : "flex-col-reverse"
                                } sm:flex-row items-center`}
                                data-aos={
                                    index % 2 === 0 ? "fade-right" : "fade-left"
                                }
                                data-aos-duration="800"
                            >
                                {index % 2 === 0 && (
                                    <img
                                        className="w-80 sm:w-1/2 h-64 object-cover mb-16 sm:mb-0 sm:mr-16 rounded-full shadow-2xl "
                                        src={section.src}
                                        alt={section.title}
                                        style={{
                                            objectPosition:
                                                section.backgroundPosition,
                                        }}
                                    />
                                )}

                                <div className="w-80 sm:w-1/2 px-4 sm:px-0">
                                    <h2 className="font-bold text-5xl font-heading mb-8 sm:mb-0 sm:mr-16 text-shadow">
                                        {section.title}
                                    </h2>
                                    <p className="text-lg leading-relaxed sm:mb-0 text-shadow">
                                        {section.text}
                                    </p>
                                </div>
                                {index % 2 !== 0 && (
                                    <img
                                        className="w-80 sm:w-1/2 h-64 object-cover mb-16 sm:mb-0 sm:ml-16 rounded-full shadow-2xl"
                                        src={section.src}
                                        alt={section.title}
                                        style={{
                                            objectPosition:
                                                section.backgroundPosition,
                                        }}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AboutPage;
