import React, { useState } from "react";
import ConnectWalletButton from "../../../ConnectWalletButton";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import CheckoutButton from "../../../CheckoutButton";

// function weiToEther(wei) {
//     const weiString = wei.toString();
//     const len = weiString.length;

//     if (len <= 18) {
//         return "0";
//     } else {
//         return weiString.slice(0, len - 18);
//     }
// }

const BuySectionCheckout = ({
    paymentOption,
    networkOption,
    nftCount,
    dollarPricePerNFT,
    maticPricePerNFT,
    ethPricePerNFT,
    isMintingActive,
    isSoldOut,
}) => {
    const [walletConnected, setWalletConnected] = useState(false);
    const [account, setAccount] = useState(null);

    const connectWallet = (account) => {
        setWalletConnected(true);
        setAccount(account);
    };

    const disconnectWallet = () => {
        setWalletConnected(false);
        setAccount(null);
    };

    // const totalMaticWeiPrice = BigInt(maticWeiPricePerNFT) * BigInt(nftCount);
    // const totalMaticPrice = weiToEther(totalMaticWeiPrice); // as a string

    // Calculate totalMaticPrice
    const totalMaticPrice = parseFloat(maticPricePerNFT) * nftCount;

    const totalDollarPrice = dollarPricePerNFT * nftCount;

    const totalEthPrice = ethPricePerNFT * nftCount;

    const proceedEnabled =
        paymentOption === "creditcard" ||
        ((paymentOption === "ethereum" || paymentOption === "matic") &&
            walletConnected);

    let checkoutMessage;
    if (isSoldOut) {
        checkoutMessage = "Sold out";
    } else if (!isMintingActive) {
        checkoutMessage = "Starts August 1, 12:00 PM, CEST";
    }

    return (
        <div>
            {checkoutMessage && <p></p>}

            {!checkoutMessage && (
                <>
                    {(paymentOption === "ethereum" ||
                        paymentOption === "matic") && (
                        <div className="py-4">
                            <ConnectWalletButton
                                onConnect={connectWallet}
                                onDisconnect={disconnectWallet}
                                network={networkOption}
                            />
                        </div>
                    )}
                </>
            )}

            <div className="border-t py-4 border-white mt-4 grid md:grid-cols-2 gap-4 items-center horizontally-aligned-checkout">
                <div>
                    <h1 className="text-md font-normal mb-2">Total:</h1>
                    <h2 className="text-xl font-semibold">
                        {paymentOption === "ethereum"
                            ? `${totalEthPrice.toFixed(3)} ETH`
                            : paymentOption === "matic"
                            ? `${totalMaticPrice} MATIC`
                            : `$ ${totalDollarPrice.toFixed(2)}`}
                    </h2>
                </div>

                <div className="flex justify-end py-4">
                    {checkoutMessage && (
                        <h2 className="text-xl font-semibold">
                            {checkoutMessage}
                        </h2>
                    )}

                    {!checkoutMessage && (
                        <>
                            {paymentOption === "creditcard" ? (
                                <CrossmintPayButton
                                    clientId={
                                        process.env
                                            .REACT_APP_CROSSMINT_CLIENT_ID
                                    }
                                    className="xmint-btn"
                                    mintConfig={{
                                        type: "erc-721",
                                        totalPrice: totalMaticPrice.toString(),
                                        numberOfNFTs: nftCount.toString(),
                                        metadataCIDs: Array.from(
                                            { length: nftCount },
                                            () =>
                                                "QmSwfmM1Q7e5CYUAuiazyHPAU9BJfodo5LjdZg9rj1fZ51"
                                        ),
                                        quantity: nftCount.toString(),
                                    }}
                                    mintTo={account}
                                    environment={
                                        process.env
                                            .REACT_APP_CROSSMINT_ENVIRONMENT
                                    }
                                    successCallbackURL="https://www.phauna.earth/congratulations"
                                />
                            ) : networkOption === "polygon" ? (
                                <CheckoutButton
                                    contractAddress={
                                        process.env.REACT_APP_CONTRACT_POLYGON
                                    }
                                    numberOfNFTs={nftCount}
                                    metadataCIDs={Array.from(
                                        { length: nftCount },
                                        () =>
                                            "QmSwfmM1Q7e5CYUAuiazyHPAU9BJfodo5LjdZg9rj1fZ51"
                                    )}
                                    price={totalMaticPrice}
                                    network={networkOption}
                                    proceedEnabled={proceedEnabled}
                                    overrideStyle={`w-full text-center py-1 font-heading text-shadow text-xl rounded-full flex items-center justify-center ${
                                        proceedEnabled
                                            ? "bg-green-500 text-white hover:bg-green-300"
                                            : "bg-gray-300 text-gray-600 cursor-not-allowed"
                                    }`}
                                    title={
                                        !proceedEnabled
                                            ? "Please connect wallet"
                                            : ""
                                    }
                                />
                            ) : (
                                <CrossmintPayButton
                                    clientId={
                                        process.env
                                            .REACT_APP_CROSSMINT_CLIENT_ID
                                    }
                                    className="xmint-btn"
                                    mintConfig={{
                                        type: "erc-721",
                                        totalPrice: totalMaticPrice.toString(),
                                        numberOfNFTs: nftCount.toString(),
                                        metadataCIDs: Array.from(
                                            { length: nftCount },
                                            () =>
                                                "QmSwfmM1Q7e5CYUAuiazyHPAU9BJfodo5LjdZg9rj1fZ51"
                                        ),
                                        quantity: nftCount.toString(),
                                    }}
                                    environment={
                                        process.env
                                            .REACT_APP_CROSSMINT_ENVIRONMENT
                                    }
                                    paymentMethod="ETH"
                                    mintTo={account}
                                    disabled={!proceedEnabled}
                                    successCallbackURL="https://www.phauna.earth/congratulations"
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BuySectionCheckout;
